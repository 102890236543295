import React from "react";

import { useMedia } from "util/useMedia";
import { dateFormat } from "util/DateUtil";
import { useCommonState } from "context/useContext";

import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";

import { TextBox } from "assets/Styles/SText";
import { CutWord } from "assets/Styles/custom/CustomText";
import { Column, Linked } from "assets/Styles/custom/CustomLayout";
import { GrayCon, FlexBox, NotMenuLayout } from "assets/Styles/SLayout";

import api from "interceptor/api";
import Pagination from "components/hooks/Pagination";
import useGetData from "data/useGetData";
import useFormData from "data/useFormData";
import NoSearch from "components/common/NoSearch";
import SearchCompo from "../../hooks/SearchCompo";
import NoListLayout from "components/common/NoListLayout";
import { SectionTitle } from "components/common/useCommonStyle";
import { t } from "i18next";

const Event = () => {
  const { isMaxMiddleM } = useMedia();
  const { setLoading } = useCommonState();
  const dispatch = useDispatch();

  const getEventInfo = () => {
    setLoading(true);
    dispatch(loadingStatus(true));

    const apiUrl = "/v1/user/event";

    api
      .get(apiUrl, {
        params: {
          ...getValue.searchValue,
          ...getEvent.extendData.pagingData,
        },
      })
      .then((res) => {
        setLoading(false);
        getEvent.setData(res.data.content);

        if (res.data.pageHelperDto) {
          getEvent.setPagingInfo(res.data.pageHelperDto);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        getEvent.setData([]);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const getEvent = useGetData({
    firstInit: true,
    api: getEventInfo,
    pageSize: "10",
  });

  const getData = getEvent.data;

  const getValue = useFormData(
    {
      value: "",
    },
    getEventInfo,
    getEvent
  );

  if (!getData) return;

  return (
    <Column $gap={isMaxMiddleM ? "20px" : "28px"}>
      <SectionTitle name={t("sectionTitle.event")} />
      <NotMenuLayout>
        <Column $gap="20px">
          <SearchCompo
            page="10"
            name="value"
            getData={getEvent}
            apiFunc={getEventInfo}
            placeholder={t("placeholder.search")}
            {...getValue}
          />

          {getData.length !== 0 && (
            <Column $gap="12px">
              {getData.map(({ no, type, title, create_at }) => (
                <Linked to={`/event/${type}/${no}`} key={no}>
                  <GrayCon $radius="12px" $pad="14px 20px" className="ho-ac">
                    <FlexBox
                      $direct={isMaxMiddleM && "column"}
                      $gap={isMaxMiddleM ? "4px" : "20px"}
                      $jus={!isMaxMiddleM && "space-between"}
                      $align={isMaxMiddleM ? "flex-start" : "center"}
                    >
                      <CutWord $class={["subB", "white"]}>{title}</CutWord>
                      <TextBox $white="nowrap" $class={["caption", "gray400"]} $minWidth="70px">
                        {dateFormat(create_at)}
                      </TextBox>
                    </FlexBox>
                  </GrayCon>
                </Linked>
              ))}
            </Column>
          )}

          {getValue.searchValue.value && getData.length === 0 ? <NoSearch /> : getData.length === 0 && <NoListLayout />}

          {getData.length !== 0 && <Pagination {...getEvent} />}
        </Column>
      </NotMenuLayout>
    </Column>
  );
};

export default Event;
