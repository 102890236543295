import { useLocation } from "react-router-dom";
import queryString from "query-string";

function Oauth() {


  // 서비스 워커가 등록 되어있다면 등록 해제
  const Unregister = async () => {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const targetScope = process.env.REACT_APP_OPTION === 'local' ? 'http://localhost:5200' : 'https://user.link-solution-test.com';
    for (const registration of registrations) {
      if (registration.scope === `${targetScope}/`) {
        await registration.unregister();
      }
    }

    return window.location.href = '/';
  }

  localStorage.removeItem("auth-user");

  const { search } = useLocation();
  const { id, no, type } = queryString.parse(search) || {};
  localStorage.setItem("auth-user", JSON.stringify({ id, no, type }));

  Unregister()

  return window.location.href = '/';

}

export default Oauth;
