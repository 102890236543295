import { validationTypeEnum } from "enum/useCommonEnum";

const getData = (data, name, index, groupNm) => {
  if (index !== undefined && index !== null) {
    if (groupNm) {
      return data[groupNm][index][name];
    } else {
      return Array.isArray(data) ? data[index][name] : data[name][index];
    }
  } else {
    return data[name] == null ? "" : data[name];
  }
};

export const isValidInput = (value, validationType) => {
  switch (validationType) {
    case validationTypeEnum.ENGLISH:
      return /^[a-zA-Z]*$/.test(value);
    case validationTypeEnum.NUMBER:
      return /^[0-9]*$/.test(value);
    case validationTypeEnum.ENGLISH_AND_NUMBER:
      return /^[a-zA-Z0-9]*$/.test(value);
    default:
      return true; // No validation if validationType is not specified
  }
};


export { getData };
