import { t } from "i18next";
import { useState } from "react";
import { useMedia } from "util/useMedia";
import { dateTimeFormat } from "util/DateUtil";
import { Column, ScrollBox } from "assets/Styles/custom/CustomLayout";
import { DuplicationIcon, CheckIcon } from "assets/components/IconComponent";
import { TableHeader, Table, TableDeps, TableRow } from "assets/Styles/STable";

import useToast from "components/hooks/useToast";
import Pagination from "components/hooks/Pagination";

export default function BettingList (props) {
  const { isScroll } = useMedia();
  const { Toast, toastOpen, openToast } = useToast();
  const [toastMsg, setToastMsg] = useState("");
  const copyText = (text) => {
    navigator.clipboard.writeText(text)
    .then(() => {
      setToastMsg(t("toast.copy_success"));
      openToast();
    })
    .catch((err) => {setToastMsg(t(err.message))});
  };
  return (
    <>
      {
        props.data.data.length !== 0 && <Column $gap="32px" $width="100%">
          <ScrollBox $pad={"20px"} $radius="12px" $backColor="var(--c-gray-700)">
            <Table className="table-box" $tLayout="fixed" $width="100%">
              <thead>
                <tr className="table-title">
                  <TableHeader $minWidth={"136px"} $width={"136px"}>
                    {t("table.bet_number")}
                  </TableHeader>
                  <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>
                    {t("table.round")}
                  </TableHeader>
                  <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>
                    {t("table.bet_pick")}
                  </TableHeader>
                  <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>
                    {t("table.status")}
                  </TableHeader>
                  <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                    {t("table.before_money")}
                  </TableHeader>
                  <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                    {t("table.bet_money")}
                  </TableHeader>
                  <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                    {t("table.win_money")}
                  </TableHeader>
                  <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                    {t("table.after_money")}
                  </TableHeader>
                  <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                    {t("table.bet_date")}
                  </TableHeader>
                  <TableHeader $minWidth={isScroll && "136px"} $width={isScroll && "136px"}>
                    {t("table.process_at")}
                  </TableHeader>
                </tr>
              </thead>
              <tbody>
                {
                  props.data.data.map((item, index) => {
                    /**
                     * 베팅 상태
                     * @returns 
                     */
                    const betStatus = () => {
                      switch(item.transaction_type) {
                        case "B":
                          return t("state.betting");
                        case "W":
                          return t("state.winning");
                        case "C":
                          return t("state.cancel");
                        default:
                          return t("state.processing");
                      }
                    };
                    /**
                     * 베팅 픽 언어 팩
                     * @returns 
                     */
                    const betPick = () => {
                      const [pick, pickType] = item.ingame_bet_pick.split("_");
                      const isNumeric = (str) => /^\d+$/.test(str);
                      
                      return isNumeric(pickType) 
                      ? `${t(`powerball.${pick}`)} ${pickType}` 
                      : `${t(`powerball.${pick}`)} ${t(`powerball.${pickType}`)}`;
                    }
    
                    return (
                      <TableRow className="table-content" key={index}>
                        <TableDeps $overflow={"hidden"} $tOverflow={"ellipsis"} $tAlign="left" onClick={() => {copyText(item.transaction_id)}}>
                          <DuplicationIcon />
                          {item.transaction_id}
                        </TableDeps>
                        <TableDeps>{item.round}</TableDeps>
                        <TableDeps>{betPick()}</TableDeps>
                        <TableDeps>{betStatus()}</TableDeps>
                        <TableDeps>{Number(item.before_money).toLocaleString()}</TableDeps>
                        <TableDeps>{Number(item.amount).toLocaleString()}</TableDeps>
                        <TableDeps>{Number(item.win_amount).toLocaleString()}</TableDeps>
                        <TableDeps>{Number(item.after_money).toLocaleString()}</TableDeps>
                        <TableDeps>{dateTimeFormat(item.bet_at)}</TableDeps>
                        <TableDeps>{dateTimeFormat(item.win_at)}</TableDeps>
                      </TableRow>
                    )
                  })
                }
              </tbody>
            </Table>
            {toastOpen && <Toast Icon={<CheckIcon />} toastText={toastMsg} />}
          </ScrollBox>
          <Pagination {...props.data} />
        </Column>
      }
    </>
  )
}