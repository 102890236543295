import React, { useRef, useState } from "react";

import { TextBox } from "assets/Styles/SText";
import { InputCon } from "assets/Styles/SInput";
import { P_GradientBtn } from "assets/Styles/SButton";
import { Empty, LineSection } from "assets/Styles/SLayout";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";
import { PasswordIcon } from "assets/components/IconComponent";

import { InputForm } from "data/useForm";
import { usePopup } from "context/useWindow";
import { useCommonState } from "context/useContext";
import { useDispatch } from "react-redux";
import {  loadingStatus } from "../../store/actions";

import { focusField, getErrorClass, validateField } from "util/FormStateUtil";

import Error from "../hooks/Error";

import api from "interceptor/api";
import useFormData from "data/useFormData";
import OauthLogin from "components/sign/OauthLogin";
import { t } from "i18next";
import { BrowserRouter as Router } from 'react-router-dom';

const Login = () => {
  const dispatch = useDispatch();
  const [hide, setHide] = useState(false);
  const [loginErr, setLoginErr] = useState(false);

  const { closePopup } = usePopup();
  const { inputError, setInputError } = useCommonState();

  const idRef = useRef(null);
  const passwordRef = useRef(null);

  const { openPopup } = usePopup();

  const HidePassword = () => {
    setHide(!hide);
  };
  const isLogin = () => {
    dispatch(loadingStatus(true));
    let getError = {};
    validateField(getValue.formValue, "id", t("error.input_id"), getError);
    validateField(getValue.formValue, "password", t("error.input_password"), getError);

    if (!getValue.formValue.id) {
      focusField(idRef);
    } else if (!getValue.formValue.password) {
      focusField(passwordRef);
    }

    localStorage.removeItem("auth-user");

    const url = "/v1/auth/login-user";
    api
      .post(url, { ...getValue.formValue })
      .then(async (res) => {
        localStorage.setItem("auth-user", JSON.stringify(res.data.content));
        const targetScope = process.env.REACT_APP_OPTION === 'local' ? 'http://localhost:5200' : 'https://user.link-solution-test.com';
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
          if (registration.scope === `${targetScope}/`) {
            await registration.unregister();
          }
        }
        window.location.replace("/");
      })
      .catch((err) => {
        const { status, data } = err.response;
        if (err.response && status === 400) {
          if (data.errors == "Failed_login") {

            openPopup({
              title: t("alert.fail_login"),
              content: <>
                {t("alert.fail_login_content1")}
                <br />
                <br />
                <div>
                  {t("alert.fail_login_content2")}
                  <TextBox onClick={() => {
                    closePopup();
                    window.location.href = "/forgot_id";
                  }}
                    $class={["subB", "purple"]}
                    $cursor="pointer"
                    $textDeco="underline">
                    {t("button.id_find")}
                  </TextBox>
                  {t("alert.or")}
                  <TextBox onClick={() => {
                    closePopup();
                    window.location.href = "/forgot_pwd";
                  }}
                    $class={["subB", "purple"]}
                    $cursor="pointer"
                    $textDeco="underline">
                    {t("button.password_find")}
                  </TextBox>
                  {t("alert.fail_login_content3")}
                </div>

              </>,
            });
          }
          else if (data.errors == "Failed_user") {
            setInputError((prevError) => ({
              ...prevError,
              password: t("error.invalid_user"),
            }));
            setLoginErr(true);
          }
        }
        // 벤 회원
        if (status === 400 && data.errors[0].msg?.code === "M001") {
          setInputError((prevError) => ({
            ...prevError,
            password: t("error.blocked_member"),
          }));
          setLoginErr(true);
        }
        // 탈퇴회원
        else if (status === 400 && data.errors[0].msg?.code === "M002") {
          setInputError((prevError) => ({
            ...prevError,
            password: t("error.leave_member"),
          }));
          setLoginErr(true);
        }
        // 승인대기 회원
        else if (status === 400 && data.errors[0].msg?.code === "M003") {
          setInputError((prevError) => ({
            ...prevError,
            password: t("error.wait_member"),
          }));
          setLoginErr(true);
        }
        // 차단된 IP
        else if (status === 400 && data.errors[0].msg?.code === "M004") {
          setInputError((prevError) => ({
            ...prevError,
            password: t("error.block_ip"),
          }));
          setLoginErr(true);
        }
        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false))
      });
    setInputError(getError);
  };

  const getValue = useFormData(
    {
      id: "",
      password: "",
    },
    isLogin
  );


  return (
    <Column $gap="32px">
      <Empty>
        <Column $gap="12px">
          <Empty>
            <InputCon
              className={getErrorClass(inputError, "id")}
              $radius="12px"
              $pad="12px 16px"
              $backColor={"var(--c-gray-800)"}
            >
              <InputForm
                name="id"
                ref={idRef}
                placeholder={t("placeholder.id")}
                backColor={"var(--c-gray-800)"}
                errors={inputError}
                setErrors={setInputError}
                autoFocus
                {...getValue}
              />
            </InputCon>
            <Error errors={inputError} name={"id"}></Error>
          </Empty>
          <Empty>
            <InputCon
              className={!loginErr && getErrorClass(inputError, "password")}
              $radius="12px"
              $pad="12px 16px"
              $backColor={"var(--c-gray-800)"}
            >
              <Between>
                <InputForm
                  height={"20px"}
                  ref={passwordRef}
                  type={hide ? "text" : "password"}
                  name="password"
                  backColor={"var(--c-gray-800)"}
                  placeholder={t("placeholder.password")}
                  errors={inputError}
                  setErrors={setInputError}
                  {...getValue}
                />
                <PasswordIcon
                  onClick={HidePassword}
                  show={hide ? true : false}
                  width="20"
                  height="20"
                  fill="var(--c-gray-300)"
                />
              </Between>
            </InputCon>
            <Error errors={inputError} name={"password"}></Error>
          </Empty>
        </Column>
        <Column $gap="12px" $pad="20px 0 0">
          <Row $gap="6px" $align="center">
            <Router>
              <TextBox onClick={() => {
                closePopup();
                window.location.href = "/forgot_id";
              }} $class={["subB", "purple"]} $cursor="pointer">
                {t("button.id_find")}
              </TextBox>
            </Router>
            <TextBox>·</TextBox>
            <Router>
              <TextBox onClick={() => {
                closePopup();
                window.location.href = "/forgot_pwd";
              }} $class={["subB", "purple"]} $cursor="pointer">
                {t("button.password_find")}
              </TextBox>
            </Router>
          </Row>
          {/* 로그인 */}
          <P_GradientBtn $radius="12px" $pad="12px 0" onClick={isLogin}>
            <TextBox $class={["subB", "white"]}>{t("button.login")}</TextBox>
          </P_GradientBtn>
          <Row $gap="8px">
            <TextBox>{t("infoMsg.first_visit")}</TextBox>
            {/* 가입하기 */}
            <Router>
              <TextBox onClick={() => {
                closePopup();
                window.location.href = "/sign";
              }} $class={["subB", "purple"]} $cursor="pointer">
                {t("button.sign_up")}
              </TextBox>
            </Router>

          </Row>
        </Column>
      </Empty>
      <Column>
        <Row $gap="12px" $align="center">
          <LineSection $width="100%" $height="1px" $borT="1px solid var(--c-gray-600)" />
          <TextBox $class={["caption", "gray400"]} $white="nowrap" $width="240px">
            {t("infoMsg.login_now")}
          </TextBox>
          <LineSection $width="100%" $height="1px" $borT="1px solid var(--c-gray-600)" />
        </Row>
        <Row $gap="8px" $pad="12px 0 0" $jus="center">
          <OauthLogin />
        </Row>
      </Column>
    </Column>
  );
};

export default Login;
