import { createGlobalStyle } from "styled-components";
import preSB from "../../../src/assets/font/pretendar/PretendarSemiBold.woff";
import preR from "../../../src/assets/font/pretendar/PretendarRegular.woff";

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "PreSB";
  src: url(${preSB}) format("woff"),
}

@font-face {
  font-family: "PreR";
  src: url(${preR}) format("woff"),
}


:root {
  /* font-style */
  --f-title: "PreSB";
  --f-header: "PreSB";

  --f-text: "PreR";
  
  --f-sub: "PreR";
  --f-subB: "PreSB";

  --f-caption: "PreR";
  --f-captionB: "PreSB";
  --f-inspection : "PreSB";
  
  /* font-size */

  --s-text: 16px;
  --s-title: 16px;
  --s-header: 22px;

  --s-sub: 14px;
  --s-caption: 12px;
  --s-60 : 60px;
  --s-inspection : 32px;

  /* font-color / back-color */

  --c-gray-200 : #C7C9D6;
  --c-gray-300 : #AAAEC2;
  --c-gray-400 : #868BA7;
  --c-gray-500 : #4F546D;
  --c-gray-600 : #383C52;
  --c-gray-700 : #282B3C;
  --c-gray-800 : #1A1C28;
  --c-gray-900 : #0F1017;

  --c-white: #f3f3f3;
  --c-hover: rgba(255, 255, 255, 0.1);
  --c-purple: #9D8DFF;
  --c-red: #FF4E4E;
  --c-blue: #5C94FF;

  --c-sky: #6DE5FF;
  --c-green: #C0FF70;
  --c-pink: #FFACDE;
  --c-opacity: rgba(157, 185, 255, 0.1);
  --c-rOpacity: rgba(255, 78, 78, 0.10);

  --c-pGradient: linear-gradient(to right, #7E51FF 0%, #561BFF 100%);
  --c-bGradient: linear-gradient(to right, #7E51FF 0%, #009EE2 100%);
  --c-gGradient: linear-gradient(to right, #53E0E0 0%, #00E276 100%);
  --c-iconColor : linear-gradient(180deg, #D0BFFF 0%, #885FFF 100%);
  /* font-height */

  --dim : 10px;

  --l-sub: 20px;
  --l-text: 22px;
  --l-title: 22px;
  --l-header: 24px;
  --l-caption: 16px;
  --l-inspection : 48px;
  --l-60 : 70px;
}

* {
  font-family: 'PreR', sans-serif;
  font-size: var(--s-sub);
  line-height: var(--l-sub);
  color: var(--c-gray-200);
}
.banner-swiper .swiper-pagination{
  position: initial;
  height: fit-content;

  @media screen and (max-width :360px) {
    height: 16px;

  }
}

.banner-swiper {
  border-radius: 12px;
}

svg {
  cursor: pointer;
}

.banner-swiper .swiper-wrapper {
  justify-content: flex-start;
}

.banner-swiper .swiper-slide {
  width: calc((100% - 32px) / 3) !important;  /* 16px 간격을 고려하여 3등분 */
  
  @media screen and (max-width : 850px) {
    width: calc(50% - 8px) !important; 

  }
  @media screen and (max-width : 700px) {
      width: 100% !important;
      max-height: 376px;
  }
}

:focus {outline: none;}

.banner-swiper .swiper-pagination-bullet {
  background-color: var(--c-gray-400) !important;

  @media screen and (max-width :360px) {
    width: 6px;
    height: 6px;
  }
}
.banner-swiper .swiper-pagination-bullet-active {
  background-color: var(--c-white) !important;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

:disabled {
  cursor: default;
}
.notfound_txt {
  text-align: center;
  padding: 30px 0;
  color: var(--c-gray-400);
}

.scroll-lock {
  height: 100%;
  overflow-y: hidden !important;
  touch-action: none !important;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: none;
}

.spiner-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
}

html {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -ms-content-zooming: none;
  touch-action: pan-x pan-y;
  -ms-touch-action: pan-x pan-y;
}

html, body {
    scrollbar-width: none;
}

.swiper {
  width: 100%;
  overflow: hidden;
  padding: initial;
  margin-left: initial;
  margin-right: initial;
}

html {
  overscroll-behavior: contain;
}

::-webkit-scrollbar {
  /* display: none; */
}

::-webkit-scrollbar-thumb {
  /* scroll-behavior: none; */
  background-color: var(--c-white);
  border-radius: 100px;
}
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  /* scrollbar-width: 3px; */
  /* Firefox */
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  background-color: var(--c-gray-900);

}

input[type="password"]::-webkit-password-view-button,
input[type="password"]::-ms-reveal {
  background-color: transparent;
  background-size: cover;
  width: 20px;
  height: 20px;
  cursor: pointer;
}


a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style: none;
}

.blind {
  opacity: 0;
  height: 0;
  visibility: hidden;
}

.hidden_content {
  opacity: 0;
  height: 0;
  visibility: hidden;
}


html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
time,
figure,
article,
nav,
header,
footer,
hgroup,
video,
audio,
aside,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

figure,
article,
header,
footer,
nav,
hgroup,
video,
audio,
aside,
main {
  display: block;
}

input,
textarea {
  -webkit-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

html {
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

body,
textarea:focus,
input:focus,
a:focus {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
  -webkit-touch-callout: none;
}

::-moz-selection {
  background: #023586;
  background: rgba(2, 53, 134, 1);
  color: var(--c-white);
}

::selection {
  background: #023586;
  background: rgba(2, 53, 134, 1);
  color: var(--c-white);
}

label {
  cursor: pointer;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="number"],
input[type="search"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

input[type="radio"],
input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  border: 0;
  margin: 0;
}

button,
input[type="file"],
input[type="image"],
input[type="reset"],
input[type="button"],
input[type="submit"] {
  border: none;
  background: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-webkit-input-placeholder {
  color: #999;
}

::-moz-placeholder {
  color: #999;
}

:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

select {
  border: 1px solid #111;
  -webkit-appearance: none;
  /* FF */
  -moz-appearance: none;
  /* safari chrome */
  appearance: none;
}

select::-ms-expand {
  display: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: var(--c-white) !important;
  background-color: var(--c-gray-700) !important;
  -webkit-box-shadow: 0 0 0 1000px var(--c-gray-700) inset !important;
  box-shadow: 0 0 0 1000px var(--c-gray-700) inset !important;
  caret-color: var(--c-white) !important;
  transition: none !important;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  //-webkit-text-fill-color: white !important; /* 글자색 */
  
  /* transition: background-color 0s ease-in-out, color 0s ease-in-out !important; */
}


.swal2-modal {
  background: rgb(41 42 47) !important;
  border: 1px solid rgba(95, 96, 97, 0.1) !important;
  box-shadow: inset 0 0 50px #13161f, inset 20px 0 300px #13161f, inset -50px 0 300px #13161f, inset 100px 200px 100px 200px #1f2229, inset -5px 0 30px #1d2024, 5px 0 30px #1d2024, -5px 0 30px #1d2024, 5px 0 30px #1d2024 !important;
  border-radius: 20px !important;
}

.swal2-modal > .swal2-title {
  color: whitesmoke !important;
}

.swal2-modal > .swal2-html-container {
  color: #cdcdcd !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #4d6bdb !important;
}
.swal2-success-circular-line-left {
  background-color: transparent !important;
}
.swal2-success-fix {
  background-color: transparent !important;
}
.swal2-success-circular-line-right {
  background-color: transparent !important;
}
`;

export default GlobalStyle;
