import { useState, useEffect } from 'react';
import styled from "styled-components";
import api from "interceptor/api";
import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";
import { useCommonState } from "context/useContext";
import { NextIcon, PrevIcon } from "assets/components/IconComponent";
import { TabBtn } from "assets/Styles/SButton";
import checkActive from "assets/images/common/check_a.svg";
import { formatKRDate, dateFormat } from "util/DateUtil";

const AttendanceBox = styled.div`
  background-color: var(--c-gray-800);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const EventBox = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const YearBox = styled.div`
  width: 120px;
  height: 30px;
  border-radius: 10px;
  background: var(--c-pGradient);
  padding: 2px 5px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const WeekBox = styled.div`
  width: 100%;  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  
  span {
    font-size: 16px;
    font-weight: 700;
  }
`

const MonthBox = styled.div`
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DayRow = styled.div`
  width: 100%;  
  display: flex;
  justify-content: space-between;
`

const EmptyBox = styled.div`
  width: 35px;
  background-color: transparent;
`

const DayBox = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 15px;
    font-weight: 700;
    color: var(--c-purple);
  }
`

const CheckIcon = styled.div`
  width: 27px;
  height: 27px;
  background: url(${checkActive}) no-repeat center;
  background-size: contain;
`

export default function AttendancePopup() {
  const dispatch = useDispatch();
  const { setLoading } = useCommonState();
  const [data, setData] = useState(null);
  const [date, setDate] = useState(new Date());
  const { no } = JSON.parse(localStorage.getItem("auth-user")) || { no: null };
  
  const weekArray = ['일', '월', '화', '수', '목', '금', '토'];

  const changeDate = (e) => {
    if (e === 'prev') {
      setDate(prev => {
        const prevDate = new Date(prev);
        prevDate.setMonth(prevDate.getMonth() - 1);
        return prevDate;
      });
    } else if (e === 'next') {
      setDate(prev => {
        const prevDate = new Date(prev);
        prevDate.setMonth(prevDate.getMonth() + 1);
        return prevDate;
      });
    }
  }

  const getAttendance = () => {
    if (!no) return;
    setLoading(true);
    dispatch(loadingStatus(true));

    api.get(`/v1/user/attendance/${no}/${dateFormat(date)}`)
    .then((res) => {
      setData(res.data.content[0]);
    })
    .catch((err) => {
      console.error(err);
    }).finally(() => {
      setLoading(false);
      dispatch(loadingStatus(false));
    });
  };

  useEffect(() => {
    getAttendance();
  }, [date]);

  if (!data) return;

  const splitWeekData = () => {
    const weekData = [];
    let tempWeek = [];
    
    data.forEach((day, index) => {
      tempWeek.push(day);
      
      if (Number(day.day_of_week) === 6 || index === data.length - 1) {
        weekData.push(tempWeek);
        tempWeek = [];
      }
    });
    
    return weekData;
  };
  const weekDayData = splitWeekData();

  return (
    <AttendanceBox>
      <EventBox>이벤트 문구 영역</EventBox>
      <Row $gap="10px">
          <TabBtn onClick={() => changeDate('prev')}>
            <PrevIcon fill="#FFF" />
          </TabBtn>
          <YearBox>{formatKRDate(date)}</YearBox>
          <TabBtn onClick={() => changeDate('next')}>
            <NextIcon fill="#FFF" />
          </TabBtn>
      </Row>
      <WeekBox>
        {weekArray.map((week, index) => (
          <span key={index}>{week}</span>
        ))}
      </WeekBox>
      <MonthBox>
        {weekDayData.map((week, weekIndex) => (
          <DayRow key={weekIndex}>
            {weekArray.map((_, dayIndex) => {
              const dayData = week.find(day => Number(day.day_of_week) === dayIndex);
              
              if (!dayData) return <EmptyBox key={dayIndex} />
              return (
                <DayBox key={dayIndex}>  
                  <span>{dayData.attendance_yn === 'Y' ? <CheckIcon /> : Number(dayData.day)}</span>
                </DayBox>
              )
            })}
          </DayRow>
        ))}
      </MonthBox>
    </AttendanceBox>
  );
};