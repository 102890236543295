import React, { useEffect, useRef, useState } from "react";

import MyMenu from "../layout/MyMenu";
import useFormData from "data/useFormData";
import Error from "components/hooks/Error";
import useToast from "components/hooks/useToast";

import { useMedia } from "util/useMedia";
import { InputForm, LabelSelectForm, SelectForm } from "data/useForm";
import { valueReplace } from "util/Inputmask";
import { getErrorClass } from "util/FormStateUtil";
import { useCommonState } from "context/useContext";

import { useDispatch, useSelector } from "react-redux";
import { loadingStatus } from "../../../store/actions";

import { InputCon } from "assets/Styles/SInput";
import { SuccessMsg, TextBox, WarningMsg } from "assets/Styles/SText";
import { Between, Column, Row } from "assets/Styles/custom/CustomLayout";
import { CheckIcon, EventMoneyIcon, MoneyIcon, ResetIcon, WarningIcon } from "assets/components/IconComponent";
import { CashPlusBtn, ContentSwap, P_GradientBtn, ResetBtn } from "assets/Styles/SButton";
import { GrayCon, Empty, FlexBox, LineSection, MyPageLayout, WarningBox, SectionBox } from "assets/Styles/SLayout";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import { t } from "i18next";
import { useAlert } from "context/useWindow";
import { Link } from 'react-router-dom';

const Deposit = () => {
  const dispatch = useDispatch();
  const { openAlert } = useAlert();
  const { isMiddleD, isMaxMiddleM } = useMedia();
  const { inputError, setInputError, setLoading } = useCommonState();
  const { Toast, toastOpen, openToast } = useToast();

  const cashPlus = (money) => {
    getValue.setFormValue((prev) => {
      let currentCash = typeof prev.amount === "string" ? valueReplace(prev.amount) : prev.amount;

      if (isNaN(currentCash)) {
        currentCash = 0;
      }

      const updatedCash = currentCash + money;

      return {
        ...prev,
        amount: updatedCash.toString(),
      };
    });
  };

  const getAccountInfo = () => {
    setInputError('')
    setLoading(true);
    dispatch(loadingStatus(true));

    const apiUrl = "/v1/account/in";

    api
      .get(apiUrl)
      .then((res) => {
        getAccount.setData(res.data.content);
        setLoading(false);
        getAccount.setError("");
      })
      .catch((err) => {
        console.error(err);
        getAccount.setError(true);
        setLoading(false);
        getAccount.setData([]);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };


  const [eventList, setEventList] = useState([]);
  useEffect(() => {
    getEventList();
  }, []);

  const getEventList = () => {
    setLoading(true);
    dispatch(loadingStatus(true));

    const apiUrl = "/v1/account/event";
    api
      .get(apiUrl)
      .then((res) => {
        if (Array.isArray(res.data.content)) {
          let eventList = res.data?.content.map((data) => { return { ...data, bonus_array: JSON.parse(data.bonus_array) } });
          eventList.unshift({ label: t("placeholder.select_event"), value: "" });
          setEventList(eventList);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  }

  /**
   * 입금신청 API
   */
  const postDeposit = () => {
    dispatch(loadingStatus(true));
    const minMoney = getData.account_in_min_money;
    const maxMoney = getData.account_in_max_money;

    const formValued = getValue.formValue;

    const cashValue = formValued.amount ? valueReplace(formValued.amount.toString()) : 0;

    // 이벤트 최소 ~ 최대 범위에 벗어났는지?

    if (selectedEvent) {
      const { max_money, min_money } = selectedEvent;

      if (Number(cashValue) < min_money || Number(cashValue) > max_money) {
        setInputError({
          event: t("error.impossible_event_deposit"),
        });
        dispatch(loadingStatus(false))
        return;
      }
    }


    if (cashValue >= minMoney && cashValue <= maxMoney) {
      const apiUrl = "/v1/account/in";
      api
        .post(apiUrl, {
          amount: Number(formValued.amount),
          account_no: String(getData.account_no),
          account_bank: getData.account_bank,
          account_name: getData.account_name,
          event_no: formValued.event,
        })
        .then((res) => {
          openToast("/my.deposit");
        })
        .catch((err) => {
          console.error(err);
          // 입금 시간 확인
          if (err.response.data.error == 'checkTime') {
            // 팝업 후 홈으로
            openAlert({
              message: t("alert.check_time"),
              Func: () => {
                window.location.reload();
              },
            });
          } else if (err.response.data.error == 'checkEvent') {
            // 팝업 후 홈으로
            openAlert({
              message: t("alert.check_event", { event: err.response.data.event }),
              Func: () => {
                window.location.reload();
              },
            });
          }
          // ~ 초 후에 다시 시도
          else {
            setInputError({ amount: `${Number(err.response.data / 1000).toFixed()} ${t("error.wait_second_try")}` })
          }
        }).finally(() => {
          dispatch(loadingStatus(false));
        });
    } else {
      setInputError({
        amount: t("error.impossible_deposit"),
      });
      dispatch(loadingStatus(false))
    }
  };

  const getAccount = useGetData({
    firstInit: true,
    api: getAccountInfo,
  });

  const getData = getAccount.data;

  const getValue = useFormData({
    amount: "",
    event: "",
    bonus: 0,
  });

  // const selectedEvent = useRef(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    if (getValue.formValue.event == '') {
      setSelectedEvent(null);
      return;
    }

    setSelectedEvent(eventList.find((data) => data.value === getValue.formValue.event));
  }, [getValue.formValue.event]);

  console.log(selectedEvent)
  useEffect(() => {
    if (!selectedEvent) return;
    let bonus = 0;

    // % 보너스
    if (selectedEvent.bonus_array[0].payment_type == "R") {
      bonus = parseInt(getValue.formValue.amount * (selectedEvent.bonus_array[0].payment_value / 100))
      if (bonus > selectedEvent.max_payment) {
        bonus = parseInt(selectedEvent.max_payment);
      }
    }
    // 고정 보너스
    else {
      selectedEvent.bonus_array.forEach((data) => {
        if (getValue.formValue.amount >= data.condition_value) {
          bonus = parseInt(data.payment_value);

          if (bonus > selectedEvent.max_payment) {
            bonus = parseInt(selectedEvent.max_payment);
          }
        }
      })
    }


    getValue.setFormValue((prev) => {
      return {
        ...prev,
        bonus: bonus || 0,
      };
    });
  }, [getValue.formValue.amount, getValue.formValue.event]);


  if (!getData) return;
  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <MyMenu />

      <MyPageLayout>
        <Column $gap="24px">
          <Row $pad="6px" $radius="30px" $backColor="var(--c-gray-900)">
            <ContentSwap className="active">{t("common.deposit")}</ContentSwap>
            <ContentSwap as={Link} to="/my.deposit">{t("sectionTitle.deposit_history")}</ContentSwap>
          </Row>
          {/* 가능 시간 , 입금중이지 않음 */}
          {
            (getData?.current_time_in_range === "Y" || getData?.account_in_limit_time_yn == "N") && !getData.isExistInAccountHistory ? (
              // 입/ 부분
              <>
                <Column $gap="10px">
                  {/* 이벤트 */}
                  <InputCon $pad="0px 16px" $radius="12px" className={getErrorClass(inputError, "event")}>
                    <Between>
                      <LabelSelectForm label={t("common.event")}
                        placeholder={t("placeholder.select_event")}
                        options={eventList}
                        name={"event"}
                        conWidth={isMaxMiddleM && "100%"}
                        selectType="search"
                        isSearchable={false}
                        {...getValue}
                      />
                    </Between>
                  </InputCon>
                  <Error name={"event"} errors={inputError}></Error>
                  {/* 이벤트 내용 */}
                  {selectedEvent && (
                    <SectionBox $pad="14px 20px" $radius="12px">
                      <Column >
                        <Row $gap="8px" $align="flex-start">
                          <TextBox $class="gray300" $white="nowrap">
                            {t("infoMsg.event_range", {
                              min: selectedEvent.min_money.toLocaleString(),
                              max: selectedEvent.max_money.toLocaleString(),
                              bonus: selectedEvent.max_payment.toLocaleString(),
                            })}
                          </TextBox>
                        </Row>
                        {selectedEvent.bonus_array.map((data, index) => (
                          <Row $gap="8px" $align="flex-start" key={index}>
                            <TextBox $class="gray300" $white="nowrap">
                              {
                                data.payment_type == "R" ?
                                  t("infoMsg.event_bonus_percent", {
                                    percent: data.payment_value,
                                  })
                                  :
                                  t("infoMsg.event_bonus_deposit", {
                                    deposit: data.condition_value.toLocaleString(),
                                    bonus: data.payment_value.toLocaleString(),
                                  })
                              }
                            </TextBox>
                          </Row>
                        ))}
                      </Column>
                    </SectionBox>
                  )}


                  {/* 이벤트 입금 금액 */}
                  <InputCon $pad="12px 16px" $radius="12px" className={getErrorClass(inputError, "amount")}>
                    <Between>
                      <TextBox $class="gray300" $white="nowrap" $width="100px">
                        {t("common.deposit_amount")}
                      </TextBox>
                      <Row $gap="6px" $width="100%">

                        <Column $width="100%" >
                          <Row $gap="6px">
                            <Row $width="100%" $gap="6px">
                              <InputForm
                                name="amount"
                                tAlign="right"
                                placeholder="0"
                                font="var(--f-subB)"
                                maskType="comma_number"
                                placeColor="var(--c-white)"
                                backColor="var(--c-gray-700)"
                                errors={inputError}
                                setErrors={setInputError}
                                {...getValue}
                                $width="100%"
                              />

                              {/* 보너스 */}
                              {getValue.formValue.event &&
                                <TextBox $color="var(--c-purple)" $white="nowrap" $width="80%" $weight="bold">
                                  {`+ ${getValue.formValue.bonus.toLocaleString()} ${t("common.bonus")}`}
                                </TextBox>
                              }

                              <Empty $width="20px" $height="20px">
                                {getValue.formValue.event ? <EventMoneyIcon width="20" height="20" /> : <MoneyIcon width="20" height="20" />}
                              </Empty>
                            </Row>
                          </Row>
                        </Column>

                      </Row>
                    </Between>
                  </InputCon>
                  <Row $gap="10px">
                    <CashPlusBtn onClick={() => cashPlus(10000)}>+10,000</CashPlusBtn>
                    <CashPlusBtn onClick={() => cashPlus(100000)}>+100,000</CashPlusBtn>
                    <CashPlusBtn onClick={() => cashPlus(1000000)}>+1,000,000</CashPlusBtn>
                    <ResetBtn
                      onClick={() => {
                        // getValue.resetForm();
                        getValue.setFormValue({
                          event: getValue.formValue.event,
                          amount: "",
                          bonus: 0,
                        });
                        setInputError({});
                      }}
                    >
                      <ResetIcon width="20" height="20" />
                    </ResetBtn>
                  </Row>
                  <Error name={"amount"} errors={inputError}></Error>

                  <TextBox $class={isMaxMiddleM ? ["caption", "gray300"] : "gray300"}>
                    {t("infoMsg.amount_range", {
                      action: t("common.deposit"),
                      min: getData?.account_in_min_money?.toLocaleString(),
                      max: getData?.account_in_max_money?.toLocaleString(),
                    })}
                  </TextBox>
                </Column>

                <Column $gap="10px">
                  <GrayCon>
                    <Between>
                      <TextBox $class="gray300">{t("common.bank")}</TextBox>
                      <TextBox $class="white">{getData?.account_bank}</TextBox>
                    </Between>
                    <Between>
                      <TextBox $class="gray300">{t("common.account_num")}</TextBox>
                      <TextBox $class="white">{getData?.account_no}</TextBox>
                    </Between>
                    <Between>
                      <TextBox $class="gray300">{t("common.account_name")}</TextBox>
                      <TextBox $class="white">{getData?.account_name}</TextBox>
                    </Between>
                  </GrayCon>
                  <TextBox $class={isMaxMiddleM ? ["caption", "gray300"] : "gray300"}>
                    {t("infoMsg.amount_message", {
                      action: t("common.deposit"),
                    })}
                  </TextBox>
                </Column>

                <LineSection $borB="1px solid var(--c-gray-600)" $width="100%" />

                <WarningBox $pad="14px 20px" $radius="12px">
                  <Column $gap="12px">
                    {
                      getData.account_in_limit_start_time && getData.account_in_limit_end_time && getData?.account_in_limit_time_yn == 'Y' && (
                        <Row $gap="8px" $align="flex-start">
                          <Row $width="18px" $height="18px">
                            <WarningIcon width="18" height="18" />
                          </Row>
                          <WarningMsg $font="var(--f-subB)" $size="var(--s-sub)" $padT="0">
                            {t("infoMsg.inspection_time_message_detail2", {
                              action: t("common.deposit"),
                              days: getData?.account_in_limit_days_text,
                              startTime: getData?.account_in_limit_start_time,
                              endTime: getData?.account_in_limit_end_time,
                            })}
                          </WarningMsg>
                        </Row>
                      )}
                    <Row $gap="8px" $align="flex-start">
                      <Row $width="18px" $height="18px">
                        <WarningIcon width="18" height="18" />
                      </Row>
                      <WarningMsg $font="var(--f-subB)" $size="var(--s-sub)" $padT="0">
                        {t("infoMsg.request_message", {
                          action: t("common.deposit"),
                          wait: getData?.account_in_wait,
                          reWait: getData?.account_in_re_wait,
                        })}
                      </WarningMsg>
                    </Row>
                  </Column>
                </WarningBox>

                <P_GradientBtn onClick={postDeposit} $pad="12px 0" $width="100%" $radius="12px">
                  {t("button.request", {
                    action: t("common.deposit"),
                  })}
                </P_GradientBtn>
              </>
            ) : (
              // 점검표시
              <GrayCon>
                <Column $gap="8px" $align="center">
                  <WarningIcon width="24" height="24" />
                  {getData.isExistInAccountHistory ? (
                    <Empty>
                      <TextBox $width="100%" $tAlign="center" $class={["white", "title"]}>
                        {t("infoMsg.request_in_progress")}
                      </TextBox>
                      <TextBox $width="100%" $tAlign="center" $class={["white", "title"]}>
                        {t("infoMsg.request_wait_message", {
                          action: t("common.deposit"),
                          wait: getData?.account_in_wait,
                        })}
                      </TextBox>
                    </Empty>
                  ) : (
                    <>
                      <TextBox $class={["white", "title"]}>
                        {t("infoMsg.inspection_time_message", {
                          action: t("common.deposit"),
                        })}
                      </TextBox>
                      <Row>
                        <TextBox $class="subB">
                          {`${t("infoMsg.inspection_time")} : ${getData?.account_in_limit_days_text} ${getData?.account_in_limit_start_time
                            }~${getData?.account_in_limit_end_time}`}
                        </TextBox>
                      </Row>
                    </>
                  )}
                </Column>
              </GrayCon>
            )}
        </Column>
        {toastOpen && <Toast Icon={<CheckIcon />} toastText={"입금 신청이 완료되었습니다."} />}
      </MyPageLayout>
    </FlexBox>
  );
};

export default Deposit;
