import styled from "styled-components";
import { t } from "i18next";

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  
  iframe {
    flex: 0 0 auto;
    width: 60%;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const BetMenuRow = styled.div`
  width: calc(100% - 100px);
  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PowerballColumn = styled.div`
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
`;

export const PowerballRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const BetMenuWrapper = styled.div`
  flex: 0 0 auto;
  width: 40%;
  max-heigth: 640px;
  padding-left: 20px;
`;

export const MenuTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--c-gray-700);
  height: 112px;
  width: 80px;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;
`;

export const BetMoneyRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
`;

export const BetMoney = styled.div`
  background-color: var(--c-gray-700);
  padding: 5px 7px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
`;

export const MoneyButton = styled.button`
  margin-left: 5px;
  padding: 1px 10px;
  background-color: var(--c-gray-500);
  border: none;
  border-radius: 4px;
  font-size : 12px;
  width: ${($width) => $width || 'auto'};
  &:hover {
    background-color: var(--c-gray-600);
  }
`;

export const BetMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${({$bgc}) => $bgc || "var(--c-gray-700)"};
  height: ${({$height}) => $height || "75px"};
  width: ${({$width}) => $width};
  padding: 10px;
  border-radius: 5px 5px 0 0;
  font-size: 18px;
  font-weight: 600;
  border: 2px solid transparent;
  transition: border-color 0.2s;
  cursor: pointer;

  &::before {
    content: "${({$defaultText}) => $defaultText}";
  }
`;

export const BetRate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-radius: 0 0 5px 5px;
  border-top: 3px solid var(--c-gray-800);
  height: ${({$height}) => $height || "30%"};
  width: ${({$width}) => $width};
  background-color: ${({$bgc}) => $bgc || "var(--c-gray-700)"};
`;

export const BettingButtonStyled = styled.button`
  width: 100%;
  padding: 15px 0;
  background: ${({$bgc}) => $bgc || "var(--c-pGradient)"};
  border: none;
  border-radius: 12px;
  font-size : 19px;
  font-weight: 700;

  &::before {
    content: "${({$text}) => $text}";
  }
`;

export const BettingButton = ({ canBet, onClick }) => {

  const bgc = canBet ? "var(--c-pGradient)" : "var(--c-gray-500)";
  const text = canBet ? t('powerball.betting') : t('powerball.not_betting');

  return (<BettingButtonStyled $bgc={bgc} $text={text} onClick={() => canBet && onClick()} />)
}

export const ColumnBetStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 2px solid ${({$borderColor}) => $borderColor};
  transition: border-color 0.2s;
  cursor: pointer;
`;

export const ColumnBet = ({ value, getValue, children }) => {

  const borderColor = value === getValue.formValue.bet_pick ? "#aba020" : "transparent";
  const handleClick = () => {
    getValue.setFormValue((prevValues) => (
      { ...prevValues, bet_pick: value }
    ));
  }

  return (
    <ColumnBetStyled $borderColor={borderColor} onClick={handleClick}>
      {children}
    </ColumnBetStyled>
  )
}

