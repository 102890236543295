import * as  TYPE from "./actionTypes"

export const login = () => {
    return {
        type: TYPE.LOGIN,
    }
}

export const loadingStatus = (loding) => {
    return {
        type: TYPE.LOADING,
        loding: loding
    }
}

export const clienInitialState = () => {
    return {
        type: TYPE.INITIAL_STATE
    }
}