import { t } from "i18next";
import { useDispatch } from "react-redux";
import { InputForm } from "data/useForm";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlert } from "context/useWindow";
import { loadingStatus } from "store/actions";
import { InputCon } from "assets/Styles/SInput";
import { Row } from "assets/Styles/custom/CustomLayout";
import { EventSourcePolyfill } from "event-source-polyfill";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import useFormData from "data/useFormData";
import BettingList from "./BettingList";

import * as S from "./styled";

export default function Powerball () {
    
  const dispatch = useDispatch();
  const { id } = useParams();
  const { openAlert } = useAlert();
  const [gameInfo, setGameInfo] = useState({});
  const [powerballData, setPowerballData] = useState({});
  const getValue = useFormData({
    amount: null,
    bet_pick: ''
  })
  
  const canBet = (getValue.formValue.amount !== null && getValue.formValue.amount >= 5000) && getValue.formValue.bet_pick !== '';
  const betMoneyArray = [100, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000];
  /**
   * 베팅 금액 업데이트
   * @param {*} e 
   */
  const BetMoneyUpdate = (e, type) => {
    if (type === 'update') {
      const value = Number(e.target.innerText.replace(/,/g, ''));

      getValue.setFormValue((prevValues) => (
        { ...prevValues, amount: Number(prevValues.amount) + value }
      ));
    }
    if (type === 'clear') {
      getValue.setFormValue((prevValues) => (
        { ...prevValues, amount: null, bet_pick: '' }
      ));
    }
  }
  /**
   * 게임 정보 조회
   */
  const GetGameInfo = () => {
    dispatch(loadingStatus(true));
    api.get(`/v1/mini-game/game-info/${id}`)
    .then((res) => {
      setGameInfo(res.data.content);
    })
    .catch((err) => {
      console.error(err);
      setGameInfo({});
    }).finally(() => {
      dispatch(loadingStatus(false));
    });
  }
  /**
   * 베팅 내역 조회
   */
  const GetBetData = () => {
    dispatch(loadingStatus(true));
    api.get(`/v1/mini-game/betting-history/${id}`, {
      params: {
        ...getBetHistory.extendData.pagingData,
      },
    })
    .then((res) => {
      getBetHistory.setError("");
      getBetHistory.setData(res.data.content);

      if (res.data.pageHelperDto) {
        getBetHistory.setPagingInfo(res.data.pageHelperDto);
      }
    })
    .catch((err) => {
      console.error(err);
      getBetHistory.setError(true);
    }).finally(() => {
      dispatch(loadingStatus(false));
    });
  }
  /**
   * 베팅
   */
  const Betting = () => {
    debugger
    dispatch(loadingStatus(true));
    api.post(`/v1/mini-game/powerball/betting`, {
      game_id: gameInfo.id,
      vendor_name: gameInfo.vendor,
      amount: getValue.formValue.amount,
      bet_pick: getValue.formValue.bet_pick,
      round: powerballData.round + 1
    })
    .then(() => {
      GetBetData();
    })
    .catch((err) => {
      const { response } = err;
      if (response.status === 400) {
        openAlert({
          title: t("common.notice"),
          message: t("powerball.bet_error"),
        });
      }
    }).finally(() => {
      BetMoneyUpdate(null, 'clear');
      dispatch(loadingStatus(false));
    });
  }
  /**
   * SSE 통신
   */
  const SSE = () => {
    const sseUrl = `${process.env.REACT_APP_BASE_URL}/v1/SSE/powerball`;
    const sse = new EventSourcePolyfill(sseUrl);

    try {
      sse.addEventListener("update", (e) => {
        try {
          const data = JSON.parse(e.data);
          setPowerballData(data);
        } catch (error) {
          console.error("Error parsing Powerball data:", error);
        }
      });

      sse.onerror = () => {
        sse.close();
      };

      return () => {
        sse.close();
      };
    } catch (error) {
      console.error("Error initializing Powerball SSE:", error);
      sse.close();
    }
  }
  const getBetHistory = useGetData({
    firstInit: true,
    api: GetBetData,
    pageSize: "10",
  });

  useEffect(() => {
    GetGameInfo();
    SSE();
  }, []);

  return (  
    <S.Column>
      <S.Wrapper>
        <iframe title="Powerball" src="https://dhpowerball.net/rpowerball/live.php" width="100%" height="640px"></iframe>
        <S.BetMenuWrapper>
          <S.Column style={{marginBottom: '10px'}}>
            <InputCon $pad="10px 15px" $radius="5px" $width="100%" $mar="0px 0px 10px 0px">
              <Row $align="center">
                <InputForm
                  name="amount"
                  type="number"
                  {...getValue}
                  backColor="var(--c-gray-700)"
                  placeholder={t('powerball.betting_amount')}
                />
                <S.MoneyButton onClick={() => BetMoneyUpdate(null, 'clear')}>
                  {t('chart.clear')}
                </S.MoneyButton>
              </Row>
            </InputCon>
            <S.BetMoneyRow>
              {
                betMoneyArray.map((item, index) => (
                  <S.BetMoney key={index} onClick={(e) => BetMoneyUpdate(e, 'update')}>{item.toLocaleString()}</S.BetMoney>
                ))
              }
            </S.BetMoneyRow>
          </S.Column>
          <S.Row>
            <S.MenuTitle>{t('powerball.powerball')}</S.MenuTitle>
            <S.BetMenuRow>
              <S.ColumnBet value="powerball_odd" getValue={getValue}>
                <S.BetMenu $bgc="#172269" $defaultText={t('powerball.odd')} $width="80px"/>  
                <S.BetRate $bgc="#172269" $width="80px">{gameInfo?.setting?.powerball_oddeven_rate}</S.BetRate>
              </S.ColumnBet>
              <S.ColumnBet value="powerball_even" getValue={getValue}>
                <S.BetMenu $bgc="#691717" $defaultText={t('powerball.even')} $width="80px"/>  
                <S.BetRate $bgc="#691717" $width="80px">{gameInfo?.setting?.powerball_oddeven_rate}</S.BetRate>
              </S.ColumnBet>
              <S.ColumnBet value="powerball_under" getValue={getValue}>
                <S.BetMenu $bgc="#172269" $defaultText={t('powerball.under')} $width="80px"/>
                <S.BetRate $bgc="#172269" $width="80px">{gameInfo?.setting?.powerball_underover_rate}</S.BetRate>
              </S.ColumnBet>
              <S.ColumnBet value="powerball_over" getValue={getValue}>
                <S.BetMenu $bgc="#691717" $defaultText={t('powerball.over')} $width="80px"/>
                <S.BetRate $bgc="#691717" $width="80px">{gameInfo?.setting?.powerball_underover_rate}</S.BetRate>
              </S.ColumnBet>
            </S.BetMenuRow>
          </S.Row>
          <S.Row>
            <S.MenuTitle>{t('powerball.generalball')}</S.MenuTitle>
            <S.BetMenuRow>
              <S.ColumnBet value="generalball_odd" getValue={getValue}> 
                <S.BetMenu $bgc="#172269" $defaultText={t('powerball.odd')} $width="80px"/>
                <S.BetRate $bgc="#172269" $width="80px">{gameInfo?.setting?.generalball_oddeven_rate}</S.BetRate>
              </S.ColumnBet>
              <S.ColumnBet value="generalball_even" getValue={getValue}>
                <S.BetMenu $bgc="#691717" $defaultText={t('powerball.even')} $width="80px"/>
                <S.BetRate $bgc="#691717" $width="80px">{gameInfo?.setting?.generalball_oddeven_rate}</S.BetRate>
              </S.ColumnBet>
              <S.ColumnBet value="generalball_under" getValue={getValue}>
                <S.BetMenu $bgc="#172269" $defaultText={t('powerball.under')} $width="80px"/>
                <S.BetRate $bgc="#172269" $width="80px">{gameInfo?.setting?.generalball_underover_rate}</S.BetRate>
              </S.ColumnBet>
              <S.ColumnBet value="generalball_over" getValue={getValue}>
                <S.BetMenu $bgc="#691717" $defaultText={t('powerball.over')} $width="80px"/>
                <S.BetRate $bgc="#691717" $width="80px">{gameInfo?.setting?.generalball_underover_rate}</S.BetRate>
              </S.ColumnBet>
            </S.BetMenuRow>
          </S.Row>
          <S.Row>
            <S.MenuTitle>{t('powerball.generalball_section')}</S.MenuTitle>
            <S.BetMenuRow>
              <S.ColumnBet value="generalball_big" getValue={getValue}>
                <S.BetMenu $bgc="#691717" $defaultText={t('powerball.big')} $width="120px"/>
                <S.BetRate $bgc="#691717" $width="120px">{gameInfo?.setting?.generalball_period_big_rate}</S.BetRate>
              </S.ColumnBet>
              <S.ColumnBet value="generalball_middle" getValue={getValue}>
                <S.BetMenu $bgc="#172269" $defaultText={t('powerball.middle')} $width="120px"/>
                <S.BetRate $bgc="#172269" $width="120px">{gameInfo?.setting?.generalball_period_middle_rate}</S.BetRate>
              </S.ColumnBet>
              <S.ColumnBet value="generalball_small" getValue={getValue}>
                <S.BetMenu $bgc="#176934" $defaultText={t('powerball.small')} $width="120px"/>
                <S.BetRate $bgc="#176934" $width="120px">{gameInfo?.setting?.generalball_period_small_rate}</S.BetRate>
              </S.ColumnBet>
            </S.BetMenuRow>
          </S.Row>
          <S.Row>
            <S.MenuTitle>{t('powerball.powerball_number')}</S.MenuTitle>
            <S.PowerballColumn>
              <S.PowerballRow>
                <S.ColumnBet value="powerball_0" getValue={getValue}>
                  <S.BetMenu $defaultText="0" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
                <S.ColumnBet value="powerball_1" getValue={getValue}>
                  <S.BetMenu $defaultText="1" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
                <S.ColumnBet value="powerball_2" getValue={getValue}>
                  <S.BetMenu $defaultText="2" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
                <S.ColumnBet value="powerball_3" getValue={getValue}>
                  <S.BetMenu $defaultText="3" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
                <S.ColumnBet value="powerball_4" getValue={getValue}>
                  <S.BetMenu $defaultText="4" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
              </S.PowerballRow>
              <S.PowerballRow>
                <S.ColumnBet value="powerball_5" getValue={getValue}>
                  <S.BetMenu $defaultText="5" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
                <S.ColumnBet value="powerball_6" getValue={getValue}>
                  <S.BetMenu $defaultText="6" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
                <S.ColumnBet value="powerball_7" getValue={getValue}>
                  <S.BetMenu $defaultText="7" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
                <S.ColumnBet value="powerball_8" getValue={getValue}>
                  <S.BetMenu $defaultText="8" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
                <S.ColumnBet value="powerball_9" getValue={getValue}>
                  <S.BetMenu $defaultText="9" $width="50px" $height="25px"/>
                  <S.BetRate $width="50px" $height="50%">{gameInfo?.setting?.powerball_rate}</S.BetRate>
                </S.ColumnBet>
              </S.PowerballRow>
            </S.PowerballColumn>
          </S.Row>
          <S.BettingButton canBet={canBet} onClick={Betting} />
        </S.BetMenuWrapper>
      </S.Wrapper>
      {getBetHistory.data && <BettingList data={getBetHistory}/>}
    </S.Column>
  )
}