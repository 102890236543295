import React, { createContext, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";
import api from "interceptor/api";
import { t } from "i18next";

const CommonContext = createContext();

const dbAuth = {
  type: {
    A: {
      label: "Admin",
      value: "A",
      key: "A",
    },
    G: {
      label: "Agent",
      value: "G",
      key: "G",
    },
    D: {
      label: "User Agent",
      value: "D",
      key: "D",
    },
    U: {
      label: "User",
      value: "U",
      key: "U",
    },
  },
}

const checkIsLogin = () => {
  let isLogin = false;

  const info = JSON.parse(localStorage.getItem("auth-user"));

  if(!info) return false

  try {
    const envProfileType = process.env.REACT_APP_TYPE?.trim();

    const hasPermission = () => {
      const type = info.type;

      switch (envProfileType) {
        case "USER":
          return type === dbAuth.type.U.value || type === dbAuth.type.D.value;
        case "ALL":
          return type === dbAuth.type.A.value || type === dbAuth.type.G.value || type === dbAuth.type.D.value || type === dbAuth.type.U.value;
        default:
          // TODO  envProfile 없으면 접근 못하게 막기
          return type === dbAuth.type.A.value || type === dbAuth.type.G.value;
        // return false;
      }
    };
    if (!hasPermission()) {
      alert(t("error.noAdminAccess"));
      localStorage.removeItem("auth-user");
      isLogin = false;
    } else {
      isLogin = true;
    }
  } catch (error) {
    console.error(error);
  }
  return isLogin;
};

export const CommonProvider = ({ children }) => {
  const [error, setError] = useState();
  const [userType, setUserType] = useState();

  const [signUp, setSignUp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [singupWaitLimit, setSignupWaitLimit] = useState(false);

  const [userState, setUserState] = useState(""); //2024.10.16 Redux 사용이후 사용 X??? 
  const [isRefer, setIsRefer] = useState(0);

  const [inputError, setInputError] = useState({});
  const [eventSource, setEventSource] = useState({});

  const [isLogin, setIsLogin] = useState(checkIsLogin);
  const [readNote, setReadNote] = useState({});
  const dispatch = useDispatch();

  let decoded = null
  let userNo = null
  const info = localStorage.getItem("auth-user");
  if (info) {
    const { no } = JSON.parse(info)
    userNo = no ? no : null;
  }

  const getUser = async () => {
    setLoading(true);
    dispatch(loadingStatus(true));
    try {
      const res = await api.get("/v1/user");
      setUserState(res.data.content.state1);
      setIsRefer(res.data.content.isRefer);
      setSignUp(res.data.content.is_exist_user_data);
      setUserType(res.data.content.type);
      setSignupWaitLimit(res.data.content.signup_wait_limit)
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      dispatch(loadingStatus(false));
    }
  };

  // useEffect(() => {
  //   if (isLogin) {
  //     getUser();
  //   }
  // }, [isLogin]);

  const contextValue = {
    getUser,

    loading,
    setLoading,

    isLogin,
    setIsLogin,

    inputError,
    setInputError,

    error,
    setError,

    isRefer,
    setIsRefer,

    singupWaitLimit,
    setSignupWaitLimit,

    eventSource,
    setEventSource,

    signUp,
    userState,

    readNote,
    setReadNote,

    userNo,
    decoded,
    userType,
  };

  return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
};

export const useCommonState = () => {
  const context = useContext(CommonContext);
  if (!context) {
    throw new Error("useCommonContext must be used within a CommonProvider");
  }
  return context;
};
