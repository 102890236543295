import useSWR from 'swr'
import { useDispatch } from 'react-redux'
import { useAlert } from 'context/useWindow'
import { t } from 'i18next'
import { clienInitialState, dataInitalState, login, loadingStatus } from 'store/actions'
import { setSessionVerified, setSessionCheckInProgress } from 'interceptor/api'
import { useEffect } from 'react'

const fetcher = async () => {
  try {
    const info = localStorage.getItem("auth-user");
    if (!info) {
      return {
        isValid: false,
        data: null
      }
    }

    const baseURL = process.env.REACT_APP_BASE_URL
    const res = await fetch(`${baseURL}/v1/session/verify`, {
      method: 'GET',
      // 쿠키 포함하여 요청
      credentials: 'include',
      headers: {
        'Accept': 'application/json'
      }
    })

    // Content-Type 헤더 확인
    const contentType = res.headers.get('content-type')
    if (!contentType || !contentType.includes('application/json')) {
      throw {
        status: res.status,
        response: {
          status: res.status,
          data: { message: 'Invalid response type' }
        }
      }
    }

    const data = await res.json()

    if (!res.ok) {
      throw {
        status: res.status,
        response: {
          status: res.status,
          data: data
        }
      }
    }

    return { isValid: true, data: data.content }
  } catch (error) {
    throw error
  }
}

export function useVerifySession() {
  const dispatch = useDispatch()
  const { openAlert } = useAlert()

  // 컴포넌트 마운트 시 세션 체크 시작
  useEffect(() => {
    const authAdmin = localStorage.getItem('auth-user');
    if (authAdmin) {
      setSessionCheckInProgress(true);
    }
    return () => {
      setSessionCheckInProgress(false);
      setSessionVerified(false);
    };
  }, []);

  const { data, error, mutate, isLoading } = useSWR('verifySession', fetcher, {
    // 포커스 갱신 
    revalidateOnFocus: true,
    // 포커스 갱신 주기 간격 : 0ms
    focusThrottleInterval: 0,
    // 일정 주기로 갱신
    refreshInterval: 0,
    // 네트워크 재연결 시 갱신
    revalidateOnReconnect: true,
    // 네트워크 재연결 시 갱신 횟수
    errorRetryCount: 3,
    onSuccess: (data) => {
      dispatch(loadingStatus(false))
      if (data?.isValid) {
        dispatch(login(data.data))
        setSessionVerified(true)
      }
    },
    onError: (err) => {
      setSessionVerified(true)
      localStorage.clear();
      let msg = ''
      switch (err?.response?.status) {
        case 401:
          msg = t('alert.session_expired')
          break
        case 403:
          msg = t('alert.forcing_logout')
          break
        case 409:
          msg = t('alert.duplicate_login')
          break
        default:
          msg = t('error.unknown')
          break
      }

      openAlert({
        message: msg,
        Func: () => {
          dispatch(clienInitialState())
          dispatch(dataInitalState())
          window.location.href = '/'
        },
      })
    },
  })

  return {
    isValid: data?.isValid ?? false,
    isLoading,
    isSessionChecked: Boolean(data || error),
    error,
    revalidate: mutate
  }
}