import Select from "react-select";
import styled, { css } from "styled-components";
import check from "../images/common/check.svg";
import checkActive from "../images/common/check_a.svg";

const createInput = ({ $size, $font, $line, $height, $border, $backColor, $maxWidth, $tAlign, $placeColor }) => css`
  width: 100%;
  text-align: ${$tAlign};
  height: ${$height ? $height : "fit-content"};
  max-width: ${$maxWidth ? $maxWidth : ""};
  border: ${$border ? $border : "none"};
  background-color: ${$backColor ? $backColor : "transparent"};
  padding: 0;
  font-family: ${$font};
  font-size: ${$size ?? "var(--s-sub)"};
  line-height: ${$line ?? "var(--l-sub)"};

  color: var(--c-white);

  &::placeholder {
    color: ${$placeColor || "var(--c-gray-300)"};
  }
  
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px ${$backColor || "transparent"} inset !important; /* 내부 그림자 */
    -webkit-text-fill-color: var(--c-white) !important; 
    transition: background-color 0s ease-in-out, color 0s ease-in-out !important;
  }
`;

const Input = styled.input`
  ${createInput}
`;

const CheckInput = styled.input.attrs({ type: "checkbox" })`
  cursor: pointer;
  appearance: none;
  margin: ${(props) => props.margin || "0 !important"};
  width: 15px !important;
  height: 15px !important;
  background: url(${check}) no-repeat center;

  &:checked {
    background: url(${checkActive}) no-repeat center;
  }
  &[disabled] {
    opacity: 0.4;
    cursor: default;
  }
  &[disabled] + label {
    opacity: 0.4;
    cursor: default;
  }
`;

const CheckRadio = styled.input.attrs({ type: "radio" })`
  cursor: pointer;
  margin: ${(props) => props.margin || "0 0 0 !important"};
  width: 20px !important;
  height: 20px !important;
  background: url(${check}) no-repeat center;

  &:checked {
    background: url(${checkActive}) no-repeat center;
  }
  &[disabled] {
    opacity: 0.5;
  }
  &[disabled] + label {
    opacity: 0.5;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  text-align: start;

  resize: none;
  line-height: var(--l-sub);
  background-color: transparent;
  border: none;
  padding: 0;

  color: var(--c-white);

  &::placeholder {
    color: var(--c-gray-300);
  }
`;

const Selects = styled(Select).attrs({ className: "select-box" })`
  width: 100%;
  border-radius: 12px;
  
  .css-b62m3t-container {
    width: 100%;
    height: 40px;
  }

  .css-1s5swe7-control,
  .css-1yxm7j6-control,
  .css-xhp73o-control {
    width: 100%;
    border-color: none !important;
    box-shadow: none;
    border: none !important;
  }

  .css-1fdsijx-ValueContainer,
  .css-3w2yfm-ValueContainer {
    width: 100%;
    padding: 0;
    align-items: center;
  }

  .css-1p3m7a8-multiValue {
    display: none;
  }
  .css-1xc3v61-indicatorContainer {
    display: none;
  }
  .css-1jqq78o-placeholder {
    font-size: ${(props) => props.size || "var(--s-sub)"};
    line-height: ${(props) => props.line || "var(--l-sub)"};
    font-family: ${(props) => props.font || "var(--f-sub)"};
    color: ${(props) => props.color || "var(--c-gray-300)"};
    white-space: nowrap;
    margin: 0;
  }
  .css-1dimb5e-singleValue {
    font-size: ${(props) => props.size || "var(--s-sub)"};
    line-height: ${(props) => props.line || "var(--l-sub)"};
    font-family: ${(props) => props.font || "var(--f-sub)"};
    color: ${(props) => props.selectColor || "var(--c-gray-300)"};
    white-space: nowrap;
    margin: 0;
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-qbdosj-Input {
    margin: 0;
    padding: 0;
  }

  .css-15lsz6c-indicatorContainer {
    display: none;
  }
  .react-select__option {
    padding: 8px;
    background-color: var(--c-gray-700); /* option 배경색 */
    font-family: var(--f-sub);
  }
  .react-select__option--is-selected {
    color: white; /* 클릭된 option 텍스트 색상 */
    font-family: var(--f-sub);
  }

  .css-b62m3t-container--is-disabled {
    background-color: #e0e0e0; // 비활성화 배경색
    color: #9e9e9e; // 비활성화 글자색
    cursor: not-allowed; // 마우스 포인터
  }

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const createInputCon = ({ $width, $mar, $pad, $radius, $height, $backColor, $cursor }) => css`
  width: ${$width ? $width : "100%"};
  padding: ${$pad};
  margin: ${$mar};
  border-radius: ${$radius};
  height: ${$height ? $height : "fit-content"};
  background-color: ${$backColor || "var(--c-gray-700)"};
  cursor : ${$cursor};
  &.not-value {
    border: 1px solid var(--c-red);
  }
  &:focus-within {
    border: 1px solid var(--c-purple);
  }
`;

const InputCon = styled.div.attrs({ className: "input-con" })`
  ${createInputCon}
`;

export { Input, Selects, CheckInput, Textarea, CheckRadio, InputCon };
