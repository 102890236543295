import * as  TYPE from "./actionTypes"

const initialState = {
  loding: false,
}

const Client = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.LOGIN:
      state = {
        loding: false,
      }
      break;
    
    case TYPE.LOADING:
      return {
        ...state,
        loding: action.loding,
      };
    
    case TYPE.INITIAL_STATE:
      return initialState;
    
    default:
      state = state
      break;
  }
  return state
}

export default Client
