import axios from "axios";
import { t } from "i18next";
import { SwalAlertCallBack } from "../util/SwalAlert"

let isSessionVerified = false;
let isSessionCheckInProgress = false;

export const setSessionVerified = (verified) => {
  isSessionVerified = verified;
};

export const setSessionCheckInProgress = (inProgress) => {
  isSessionCheckInProgress = inProgress;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

api.interceptors.request.use(async (config) => {
  const authAdmin = localStorage.getItem('auth-user');
  
  // 로그인 상태가 아니면 바로 진행
  if (!authAdmin) {
    return config;
  }

  // 로그인 상태이고 세션 체크가 진행 중일 때만 대기
  if (isSessionCheckInProgress && !isSessionVerified && !config.url.includes('/session/verify')) {
    await new Promise((resolve) => {
      const checkSession = () => {
        if (isSessionVerified || !isSessionCheckInProgress) {
          resolve();
        } else {
          setTimeout(checkSession, 100);
        }
      };
      checkSession();
    });
  }

  if (authAdmin) {
    config.headers['Authorization'] = `Bearer ${authAdmin}`;
  }
  config.withCredentials = true;
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error; 
    if (response.status === 403) {
      localStorage.clear();
      return window.location.href = `${window.location.origin}/`
    }
    if (response.status === 401 || response.status === 409) { 
      const text = `accessUser.${response.status === 401 ? "session_expire" : "duplicate_login"}`;
      localStorage.clear();
      return SwalAlertCallBack('error', t("accessUser.logout"), `<p>${t(text)}</p>`, () => {
        window.location.href = `${window.location.origin}/`
      });
    }
    return Promise.reject(error);
  }
);

export default api;
