import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { loadingStatus } from "../../store/actions";
import { useMedia } from "util/useMedia";
import { GameInfoBoxTab } from "assets/Styles/SLayout";
import { useCommonState } from "context/useContext";
import { CutWord } from "assets/Styles/custom/CustomText";
import { Column, ContentBox, Grid, Row, Thumbnail } from "assets/Styles/custom/CustomLayout";
import { LinkIcon } from "assets/components/IconComponent";

import { SelectForm, SelectFormMulti } from "data/useForm";
import useGetEnum from "enum/useGetEnum";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import useFormData from "data/useFormData";

import SearchCompo from "components/hooks/SearchCompo";
import NoSearch from "components/common/NoSearch";
import MorePaging from "components/hooks/MorePaging";
import { SectionTitle } from "components/common/useCommonStyle";
import NoListLayout from "components/common/NoListLayout";
import { t } from "i18next";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { useAlert } from "context/useWindow";

const DivStyleBox = styled.div``

const TableGame = () => {
  const dispatch = useDispatch();
  const enums = useGetEnum();
  const pathname = useLocation();
  const { menu_name, menu_code } = useParams();
  const { setLoading, isLogin } = useCommonState();
  const { isMaxTablet, isMaxMiddleM } = useMedia();
  const [gameOptions, setGameOptions] = useState([]);
  const { openAlert } = useAlert();

  const getGameList = () => {
    dispatch(loadingStatus(true))

    const apiUrl = "/v1/game/side-menu/game";

    api
      .get(apiUrl, {
        params: {
          menu_code: menu_code,
          ...getValue?.searchValue,
          ...useGame.extendData.pagingData,
        },
      })
      .then((res) => {
        useGame.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useGame.setPagingInfo(res.data.pageHelperDto);
        }
        useGame.setError("");
      })
      .catch((err) => {
        console.error(err);
        useGame.setData([]);
        useGame.setError(true);
      }).finally(() => {
        dispatch(loadingStatus(false))
      });
  };

  const gameCompanyList = () => {
    dispatch(loadingStatus(true))

    const apiUrl = "/v1/game/provider-list";
    const params = {
      ...(getValue?.searchValue?.value ? { value: getValue.searchValue.value } : {}),
      menu_code: menu_code,
    };

    api
      .get(apiUrl, { params })
      .then((res) => {
        setGameOptions(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      }).finally(() => {
        dispatch(loadingStatus(false))
      });
  };
  const errorAlert = () => {
    openAlert({
      title: t("alert.game_error_title"),
      message: t("alert.game_error"),
    });
  };
  const ApiCallback = (vendor, id) => {
    dispatch(loadingStatus(true))
    setLoading(true);

    const apiUrl = "/v1/user-game/game-launch-link";
    api
      .get(apiUrl, {
        params: {
          game_id: id,
          vendor: vendor,
        },
      })
      .then((res) => {
        window.open(res.data.content.link, '_blank');
      })
      .catch((error) => {
        console.error(error);
        errorAlert();
      }).finally(() => {
        setLoading(false);
        dispatch(loadingStatus(false))
      });
  }

  const useGame = useGetData({
    pageSize: "32",
    select: "order",
    selectData: "latest",
    firstInit: true,
    multiSelect: "provider",
    multiData: [],
  });

  const getValue = useFormData(
    {
      value: "",
    },
    getGameList,
    useGame
  );

  useEffect(() => {
    gameCompanyList();
  }, [getValue.searchValue]);

  useEffect(() => {
    getGameList();
  }, [pathname]);

  if (!useGame.data) return;

  return (
    <Column $gap={isMaxMiddleM ? "20px" : "28px"}>
      <SectionTitle name={menu_name} />

      <Column $gap="12px">
        <SearchCompo
          name="title"
          apiFunc={getGameList}
          page="32"
          getData={useGame}
          placeholder={t("placeholder.search")}
          {...getValue}
        />
      </Column>

      <Column $gap={isMaxMiddleM ? "16px" : "20px"}>
        <Row $gap="12px" $width={isMaxMiddleM && "100%"}>
          <SelectFormMulti name={"provider"} options={gameOptions} placeholder={t("placeholder.vendor")} {...useGame} />
          <SelectForm
            name={"order"}
            conWidth={isMaxMiddleM && "100%"}
            options={enums.sortOrder}
            placeholder={t("state.popular")}
            {...useGame}
          />
        </Row>
        {useGame?.data?.length > 0 && (
          <>
            <Grid>
              {useGame?.data?.map(({ title, thumbnail, id, vendor, url, is_inner_game }) => (
                <ContentBox
                  as={Link}
                  key={vendor + id}
                  $direct="column"
                  $align="center"
                  $backColor="var(--c-gray-700)"
                  $radius={isMaxMiddleM ? "8px" : "12px"}
                  $cursor="pointer"
                  to={is_inner_game == "Y" ? `/${url}` : `/game_zone/${vendor}/${id}`}
                >
                  <Thumbnail
                    $width="100%"
                    src={thumbnail}
                    $height={isMaxMiddleM ? "104px" : "179px"}
                    $radius={isMaxMiddleM ? "8px 8px 0 0" : "12px 12px 0 0"}
                    alt={vendor + id}
                  />
                  <GameInfoBoxTab $pad={isMaxTablet && "8px 12px"}>
                    <CutWord $maxWidth="100%">{title}</CutWord>
                    <DivStyleBox onClick={(e) => {
                      if (isLogin) {
                        e.preventDefault();
                        e.stopPropagation();
                        ApiCallback(vendor, id)
                      }
                    }}
                    >
                      {is_inner_game == "Y" ? null : <LinkIcon
                        width="24"
                        height="24"
                      />}
                    </DivStyleBox>
                  </GameInfoBoxTab>
                </ContentBox>
              ))}
            </Grid>
          </>
        )}
      </Column>
      {useGame?.data?.length > 0 && (
        <MorePaging num={useGame?.data?.length} totalNum={useGame?.pagingInfo?.offset} {...useGame} />
      )}
      {getValue.searchValue.value && useGame?.data?.length === 0 ? (
        <NoSearch />
      ) : (
        useGame?.data?.length === 0 && <NoListLayout />
      )}
    </Column>
  );
};

export default TableGame;
