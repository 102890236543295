import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { TabBtn } from "assets/Styles/SButton";
import { TextBox } from "assets/Styles/SText";
import { Row } from "assets/Styles/custom/CustomLayout";
import { NextIcon, PrevIcon } from "assets/components/IconComponent";
import { getData, isValidInput } from "util/FormUtils";
import { CheckInput, Input, InputCon, Selects, Textarea } from "assets/Styles/SInput";
import { CustomOption, useCustomStyles, ValueContainer } from "components/common/SelectCustoms";
import { Slide } from "assets/Styles/SLayout";
import { Swiper } from "swiper/react";
import { formatNumberWithCommas, valueReplace } from "util/Inputmask";
import Error from "components/hooks/Error";
import { getErrorClass } from "util/FormStateUtil";

const SlideForm = ({ position, prevEl, nextEl, width, height, radius, disabled, backColor }) => {
  return (
    <Row $gap="6px">
      <TabBtn
        $position={position}
        className={prevEl}
        disabled={disabled}
        $width={width}
        $radius={radius}
        $height={height}
        $backColor={backColor}
      >
        <PrevIcon />
      </TabBtn>

      <TabBtn
        $position={position}
        className={nextEl}
        disabled={disabled}
        $width={width}
        $radius={radius}
        $height={height}
        $backColor={backColor}
      >
        <NextIcon />
      </TabBtn>
    </Row>
  );
};

const TabsForm = ({
  name = "type",
  options,
  activeBg,
  activeTc,
  extendData,
  onTypeClick,
  // style
  pad,
  backColor,
  width,
  height,
  disabled,
  radius,
  rowGap,
  textClass,
}) => {
  const optionArray = Array.isArray(options) ? options : Object.values(options);
  const selectedValue = extendData.pagingData[name];

  return (
    <Swiper spaceBetween={4} slidesPerView={"auto"} slidesPerGroup={1} grabCursor={true}>
      <Row $gap={rowGap} $align="center">
        {optionArray.map(({ label, value, key, IconComponent, iconProps }) => {
          const isActive = selectedValue === value || (!selectedValue && value === "");
          return (
            <Slide key={key}>
              <TabBtn
                key={key}
                name={name}
                disabled={disabled}
                $gap={IconComponent ? "6px" : ""}
                className={isActive && "active"}
                onClick={() => onTypeClick(value)}
                //style
                $pad={pad}
                $width={width}
                $radius={radius}
                $height={height}
                $backColor={isActive ? activeBg : backColor}
              >
                {IconComponent &&
                  React.cloneElement(IconComponent, {
                    ...iconProps,
                    fill: isActive ? "var(--c-white)" : iconProps.fill,
                    width: "16",
                    height: "16",
                  })}
                <TextBox $class={isActive ? activeTc : textClass}>{label}</TextBox>
              </TabBtn>
            </Slide>
          );
        })}
      </Row>
    </Swiper>
  );
};

const SelectForm = ({
  name,
  index,
  errors,
  groupNm,
  options,
  conWidth,
  onChange,
  onChangeEx,
  disabled,
  errorMsg,
  formValue,
  setErrors,
  selectType,
  extendData,
  placeholder,
  // 스타일 조작
  pad,
  font,
  size,
  line,
  color,
  height,
  minWidth,
  minHeight,
  errorPadT,
  selectColor,
}) => {
  const customStyles = useCustomStyles(pad, size, font, line, minWidth, minHeight, height);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const newSelectedOption = options?.find(
      (option) => option.value === getData(formValue ? formValue : extendData.pagingData, name, index, groupNm)
    );
    if (newSelectedOption) {
      setSelectedOption(newSelectedOption);
    }
  }, [formValue, name, index, groupNm]);

  const onChanged = (event, status) => {
    const newSelectedOption = options.find((option) => option.value === event.value);
    setSelectedOption(newSelectedOption || "");

    if (selectType === "search") {
      onChange(name, event ? event.value : "", index, groupNm);
    } else {
      onChange(event, status);
    }

    if (onChangeEx) {
      onChangeEx(name, event ? event.value : "", index, groupNm);
    }

    if (newSelectedOption && errors) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  if (options && !Array.isArray(options)) {
    options = Object.values(options);
  }

  options = options?.map((option) => ({
    value: option.value,
    label: option.label,
    key: option.key,
  }));

  const handleBlur = () => {
    if (!selectedOption && errors) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMsg,
      }));
    }
  };
  return (
    <>
      <InputCon
        $width={conWidth ? conWidth : "fit-content"}
        $radius="12px"
        className={errors && errors[name] ? getErrorClass(errors, name) : ""}
      >
        <Selects
          name={name}
          options={options}
          onChange={onChanged}
          isDisabled={disabled}
          styles={customStyles}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={selectedOption || ""}
          // 스타일 조작
          size={size}
          font={font}
          line={line}
          color={color}
          selectColor={selectColor}
          minHeight="20px"
        />
      </InputCon>
      {errors && errors[name] && <Error name={name} errors={errors} padT={errorPadT} />}
    </>
  );
};


const LabelSelectForm = ({
  name,
  label,
  index,
  errors,
  groupNm,
  options,
  conWidth,
  onChange,
  onChangeEx,
  disabled,
  errorMsg,
  formValue,
  setErrors,
  selectType,
  extendData,
  placeholder,
  // 스타일 조작
  pad,
  font,
  size,
  line,
  color,
  height,
  minWidth,
  minHeight,
  errorPadT,
  selectColor,
  isSearchable,
}) => {
  const customStyles = useCustomStyles(pad, size, font, line, minWidth, minHeight, height);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const newSelectedOption = options?.find(
      (option) => option.value === getData(formValue ? formValue : extendData.pagingData, name, index, groupNm)
    );
    if (newSelectedOption) {
      setSelectedOption(newSelectedOption);
    }
  }, [formValue, name, index, groupNm]);

  const onChanged = (event, status) => {
    const newSelectedOption = options.find((option) => option.value === event.value);
    setSelectedOption(newSelectedOption || "");

    if (selectType === "search") {
      onChange(name, event ? event.value : "", index, groupNm);
    } else {
      onChange(event, status);
    }

    if (onChangeEx) {
      onChangeEx(name, event ? event.value : "", index, groupNm);
    }

    if (newSelectedOption && errors) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  if (options && !Array.isArray(options)) {
    options = Object.values(options);
  }

  options = options?.map((option) => ({
    value: option.value,
    label: option.label,
    key: option.key,
  }));

  const handleBlur = () => {
    if (!selectedOption && errors) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMsg,
      }));
    }
  };
  return (
    <Row $align="center" $width="100%">
      <TextBox $class="gray300" $white="nowrap" $width="200px">{label}</TextBox>
      <Selects
        name={name}
        options={options}
        onChange={onChanged}
        isDisabled={disabled}
        styles={customStyles}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={selectedOption || ""}
        // 스타일 조작
        size={size}
        font={font}
        line={line}
        color={color}
        selectColor={selectColor}
        minHeight="20px"
        isSearchable={isSearchable} // 검색 비활성화
      />
      {errors && errors[name] && <Error name={name} errors={errors} padT={errorPadT} />}
    </Row>
  );
};

const SelectFormMulti = ({
  name = "provider",
  options,
  addProvider,
  disabled,
  placeholder,
  pad,
  size,
  font,
  line,
  minWidth,
  minHeight,
  height,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const customStyles = useCustomStyles(pad, size, font, line, minWidth, minHeight, height);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    addProvider(selected ? selected.map((opt) => opt.value) : []);
  };

  if (options) {
    if (!Array.isArray(options)) {
      options = Object.values(options);
    }

    options = options?.map((option) => ({
      value: option.value,
      label: option.label,
      key: option.key,
      count: option.count,
    }));
  }

  return (
    <Selects
      name={name}
      options={options}
      disabled={disabled}
      styles={customStyles}
      onChange={handleChange}
      value={selectedOptions}
      placeholder={placeholder}
      components={{
        ValueContainer,
        Option: (props) => <CustomOption {...props} addProvider={(value) => handleChange({ target: { value } })} />,
      }}
      // 멀티플옵션
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isSearchable={true}
      menuPlacement="auto"
    />
  );
};

const InputForm = forwardRef(
  (
    {
      type,
      name,
      index,
      onBlur,
      errors,
      padding,
      maskType,
      disabled,
      onChange,
      setErrors,
      onKeyDown,
      formValue,
      placeholder,
      validationType,
      // 스타일 조작 props
      size,
      font,
      line,
      width,
      border,
      height,
      tAlign,
      groupNm,
      maxWidth,
      backColor,
      placeColor,
    },
    ref
  ) => {
    const initialValue = useMemo(
      () => getData(formValue, name, index, groupNm) || "",
      [formValue, name, index, groupNm]
    );

    const onChanged = (e) => {
      if (maskType === "comma_number") {
        onChange(name, valueReplace(e.target.value), index, groupNm);
      } else if (isValidInput(e.target.value, validationType)) {
        onChange(name, e.target.value, index, groupNm);
      }
      if (errors && errors[name]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
        }));
      }
    };

    const valued = maskType === "comma_number" ? formatNumberWithCommas(initialValue.toString()) : initialValue;

    return (
      <>
        <Input
          ref={ref}
          name={name}
          value={valued}
          onBlur={onBlur}
          disabled={disabled}
          onChange={onChanged}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          type={type || "text"}
          // style props
          autoComplete="off"
          $size={size}
          $font={font}
          $line={line}
          $width={width}
          $pad={padding}
          $height={height}
          $border={border}
          $tAlign={tAlign}
          $maxWidth={maxWidth}
          $backColor={backColor}
          $placeColor={placeColor}
        />
      </>
    );
  }
);

const TextareaForm = ({
  name,
  width,
  border,
  height,
  disabled,
  onChange,
  onKeyDown,
  formValue,
  placeholder,
  readOnly,
  maxLength,

  groupNm,
  index,
}) => {
  const onChanged = (event) => {
    const newValue = event.target.value;
    if (maxLength && newValue.length > maxLength) {
      return;
    }
    onChange(name, newValue, index, groupNm);
  };

  return (
    <Textarea
      name={name}
      width={width}
      height={height}
      $border={border}
      disabled={disabled}
      onChange={onChanged}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      readOnly={readOnly}
      value={getData(formValue, name, index, groupNm)}
    />
  );
};

const CheckForm = ({ keys, name, base, disabled, eachData }) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const allChecked = name === "all";

  const eventHandle = (key) => {
    let newData;
    if (allChecked) {
      newData = base.data.map((item) => ({
        ...item,
        isChecked: !checkedAll,
      }));
      setCheckedAll(!checkedAll);
    } else {
      newData = base.data.map((item) => ({
        ...item,
        isChecked: item.no === key ? !(item.isChecked || false) : item.isChecked,
      }));
    }
    base.setData(newData);
  };

  const allItemsChecked = base.data?.every((item) => item.isChecked);
  useEffect(() => {
    if (allItemsChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [allItemsChecked]);

  const isChecked = allChecked && base.data.length !== 0 ? checkedAll : (eachData && eachData.isChecked) || false;

  return (
    <>
      <CheckInput
        id={keys}
        checked={isChecked}
        disabled={disabled}
        onClick={eventHandle}
        onChange={() => eventHandle(keys)}
      />
    </>
  );
};

export { SlideForm, TabsForm, SelectForm, LabelSelectForm, SelectFormMulti, InputForm, TextareaForm, CheckForm };
