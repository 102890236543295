import Login from "components/popup/Login";
import { useAlert, usePopup } from "context/useWindow";
import { useCommonState } from "context/useContext";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useStateCheck = () => {
  const navigate = useNavigate();
  const { openAlert } = useAlert();
  const { openPopup } = usePopup();
  const { isLogin } = useCommonState();
  const infoData = useSelector(state => state.Data)

  const showPopup = () => {
    openPopup({
      content: Login,
      title: t("sectionTitle.login"),
    });
  };

  const showAlert = () => {
    openAlert({
      title: t("alert.join_wait"),
      message: t("alert.join_wait_message"),
    });
  };

  const stateCheck = (url) => {
    if (!isLogin) {
      showPopup();
    } else if (url === "/customer") {
      navigate(url)
    } else if (infoData.state === "W") {
      showAlert();
    } else {
      if (url.startsWith("http://") || url.startsWith("https://")) {
        window.open(url, "_blank");
      } else {
        navigate(url)
      }
    }
  };

  return stateCheck;
};

export const LoginCheck = () => {
  const info = localStorage.getItem("auth-user");

  if (info) {
    if (info) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
