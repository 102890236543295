import React from "react";
import { useParams } from "react-router-dom";

import { useMedia } from "util/useMedia";
import { useCommonState } from "context/useContext";

import { useDispatch } from "react-redux";
import { loadingStatus } from "store/actions";

import { TextBox } from "assets/Styles/SText";
import { PrevIcon } from "assets/components/IconComponent";
import { Column, Row, RowLink, Thumbnail } from "assets/Styles/custom/CustomLayout";
import { GrayCon, FlexBox, MyPageLayout, LineSection, Empty, PadBox } from "assets/Styles/SLayout";

import api from "interceptor/api";
import useGetData from "data/useGetData";
import { dateTimeFormat } from "util/DateUtil";
import { t } from "i18next";
import { SectionTitle } from "components/common/useCommonStyle";
import useGetEnum from "enum/useGetEnum";

const EventDetail = () => {
  const enums = useGetEnum();
  const { no, type } = useParams();
  const { setLoading } = useCommonState();
  const { isMiddleD, isMaxMiddleM } = useMedia();
  const dispatch = useDispatch();

  const getEventInfo = () => {
    setLoading(true);
    dispatch(loadingStatus(true));

    const apiUrl = `/v1/user/event/${type}/${no}`;

    api
      .get(apiUrl)
      .then((res) => {
        setLoading(false);
        getEvent.setError("");
        if (type == 'eveNot') {
          getEvent.setData({ ...res.data.content, bonus_array: JSON.parse(res.data.content.bonus_array) });
        } else {
          getEvent.setData(res.data.content);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        getEvent.setData([]);
        getEvent.setError(true);
      }).finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  const getEvent = useGetData({
    firstInit: true,
    api: getEventInfo,
  });

  const getData = getEvent.data;

  if (!getData) return;
  return (
    <FlexBox $gap={isMiddleD && "28px"} $direct={isMiddleD ? "row" : "column"}>
      <SectionTitle name={t("sectionTitle.event")} />
      <MyPageLayout>
        <Column $gap="24px">
          <RowLink to="/event" $gap="4px">
            <PrevIcon width="16" height="16" fill="var(--c-gray-300)" />
            <TextBox $class="gray300">{t("button.return_list")}</TextBox>
          </RowLink>

          <GrayCon $radius="12px" $pad={isMaxMiddleM ? "20px" : "24px 28px"}>
            <Column $gap="20px" $align="left">
              <Empty>
                <TextBox $class={[isMaxMiddleM ? "title" : "header", "white"]}>{getData.title}</TextBox>
                <PadBox $pad="8px 0 0">
                  <TextBox $class="gray300">{dateTimeFormat(getData.create_at)}</TextBox>
                </PadBox>
              </Empty>

              <LineSection $width="100%" $borB="1px solid var(--c-gray-500)" />

              {type == 'notice' ?
                <>
                  <TextBox $class="white">{getData.contents}</TextBox>

                  {getData.uuid && (
                    <Thumbnail $radius="12px" src={process.env.REACT_APP_IMG_URL + getData.uuid} alt={getData.name} />
                  )}
                </>

                :

                type == 'eveNot' ?
                  <>
                    {/* 내용 */}
                    {getData.content && (
                      <TextBox
                        $class="white"
                        {...(getData.content.includes('<') && getData.content.includes('>')
                          ? { dangerouslySetInnerHTML: { __html: getData.content } }
                          : { children: getData.content }
                        )}
                      />
                    )}

                    {/* 금액 범위 */}
                    {(getData.max_money || getData.min_money || getData.max_payment) && (
                      <Column>
                        <TextBox $class="gray300">
                          {
                            t('infoMsg.event_range', {
                              min: getData.min_money.toLocaleString(),
                              max: getData.max_money.toLocaleString(),
                              bonus: getData.max_payment.toLocaleString(),
                            })
                          }
                        </TextBox>
                      </Column>
                    )}

                    {/* 이미지 */}
                    {getData.img && (<Thumbnail $radius="12px" src={process.env.REACT_APP_IMG_URL + getData.img} alt={getData.name} />)}

                    {/* 기간 */}
                    {getData.start_datetime && getData.end_datetime &&
                      <Column>
                        <TextBox $class="white">{t('notice.period')}</TextBox>
                        <TextBox $class="gray300">{`${formatDate(getData.start_datetime)} ~ ${formatDate(getData.end_datetime)}`}</TextBox>
                      </Column>
                    }

                    {/* 보너스 */}
                    {getData.bonus_array && (<Column>
                      <TextBox $class="white">{t('notice.bonus')}</TextBox>
                      {getData.bonus_array.map((data, index) => (
                        <Row $gap="8px" $align="flex-start">
                          <TextBox $class="gray300" $white="nowrap">
                            {
                              data.payment_type == "R" ?
                                t("infoMsg.event_bonus_percent", {
                                  percent: data.payment_value,
                                })
                                :
                                t("infoMsg.event_bonus_deposit", {
                                  deposit: data.condition_value.toLocaleString(),
                                  bonus: data.payment_value.toLocaleString(),
                                })
                            }
                          </TextBox>
                        </Row>
                      ))}
                    </Column>
                    )}

                    {/* 제약 조건 */}
                    {
                      getData.condition_day_YN == 'Y' ||
                        getData.condition_time_YN == 'Y' ||
                        getData.condition_deposit_YN == 'Y' ||
                        getData.condition_withdrawal_YN == 'Y'
                        ?
                        <Column $gap="4px">
                          <LineSection $width="100%" $borB="1px solid var(--c-gray-500)" />
                          <TextBox $class="white">{t('notice.condition')}</TextBox>
                        </Column> : null
                    }


                    {/* 제약 조건 : 요일*/}
                    {getData.condition_day_YN == 'Y' && (
                      <Column>
                        <TextBox $class="white">{t('notice.days')}</TextBox>
                        <TextBox $class="gray300">
                          {getData.condition_day.split('').map((day, index) => (
                            day == 'Y' ? enums.day[index]?.label : null
                          )).filter((data) => { return data !== null }).join(', ')
                          }
                        </TextBox>
                      </Column>
                    )}

                    {/* 제약 조건 : 시간*/}
                    {getData.condition_time_YN == 'Y' && (
                      <Column>
                        <TextBox $class="white">{t('notice.time')}</TextBox>
                        {getData.condition_endless == 'Y' ?
                          <TextBox $class="gray300">
                            {t('infoMsg.event_bonus_endless')}
                          </TextBox>
                          :
                          <TextBox $class="gray300">
                            {`${getData.condition_start_time} ~ ${getData.condition_end_time}`}
                          </TextBox>
                        }
                      </Column>
                    )}

                    {/* 제약 조건 : 입금 제한*/}
                    {getData.condition_deposit_YN == 'Y' && (
                      <Column>
                        <TextBox $class="white">{t('notice.acc_deposit')}</TextBox>
                        <TextBox $class="gray300">
                          {
                            getData.condition_deposit_nolimit == 'Y' ?
                              t('infoMsg.event_bonus_nolimit')
                              :
                              `${getData.condition_min_deposit.toLocaleString()} ${t('notice.won')} ~ ${getData.condition_max_deposit.toLocaleString()} ${t('notice.won')}`
                          }
                        </TextBox>
                      </Column>
                    )}

                    {/* 제약 조건 : 출근 제한*/}
                    {getData.condition_withdrawal_YN == 'Y' && (
                      <Column>
                        <TextBox $class="white">{t('notice.acc_withdraw')}</TextBox>
                        <TextBox $class="gray300">
                          {
                            getData.condition_withdrawal_nolimit == 'Y' ?
                              t('infoMsg.event_bonus_nolimit')
                              :
                              `${getData.condition_min_withdrawal.toLocaleString()} ${t('notice.won')} ~ ${getData.condition_max_withdrawal.toLocaleString()} ${t('notice.won')}`
                          }
                        </TextBox>
                      </Column>
                    )}
                  </>

                  :

                  null
              }
            </Column>
          </GrayCon>
        </Column>
      </MyPageLayout>
    </FlexBox>
  );


};

const formatDate = (dateString) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6) - 1; // 월은 0부터 시작
  const day = dateString.substring(6, 8);
  const date = new Date(year, month, day);

  // 원하는 포맷으로 변환
  return `${year.substring(2)}년 ${String(month + 1).padStart(2, '0')}월 ${day}일`;
};

export default EventDetail;
