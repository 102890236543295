import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "interceptor/api";
import useGetData from "data/useGetData";
import { useDispatch, useSelector } from "react-redux";
import { loadingStatus, updateMoney } from "../../store/actions";
import { useCommonState } from "context/useContext";
import { Column, Row, ScrollBox } from "assets/Styles/custom/CustomLayout";
import { useAlert } from "context/useWindow";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Table, TableDeps, TableHeader, TableRow } from "assets/Styles/STable";
import useFormData from "data/useFormData";
import { CheckRadio, InputCon } from "assets/Styles/SInput";
import { InputForm, SelectForm } from "data/useForm";
import { TextBox } from "assets/Styles/SText";
import { EventSourcePolyfill } from "event-source-polyfill";
import { CheckIcon, DuplicationIcon } from "assets/components/IconComponent";
import { useMedia } from "util/useMedia";
import { FlexBox } from "assets/Styles/SLayout";
import useToast from "components/hooks/useToast";

const green = '#55DD74'
const red = '#F24141'
const disbleGreen = '#3a854b'
const disbleRed = '#852424'

const Nasdaq = () => {
  const { isMaxTablet: isMobile, isScroll, isMiddleM } = useMedia();

  const dispatch = useDispatch();
  const gameName = useLocation().pathname.split('/')[1]
  const ticker = useLocation().pathname.split('/')[2]
  const { type } = useParams();
  const { error } = useCommonState();
  const navigate = useNavigate();
  const [activeBattingTab, setActiveBattingTab] = useState(0);
  const [viewTypeTable, setViewTypeTable] = useState()
  const [displayRemainingSecondsRef, setDisplayRemainingSecondsRef] = useState(0);
  const { Toast, toastOpen, openToast } = useToast();
  const [toastMsg, setToastMsg] = useState("");
  const getValue = useFormData({
    value: '0',
    game_no: '',
    label: '',
    game_rule01: parseInt(type),
    game_rule02: '',
    game_rule03: '',
    game_rule04: '',
    game_rule05: '',
    search_game_rule01: '',
    search_game_rule02: '',
    search_game_rule03: '',
    search_game_rule04: '',
    search_game_rule05: '',
    search_game_no: '',
    search_type: type,
    round_id: '',
  });
  const wsRef = useRef(null);
  const { openAlert } = useAlert();
  const [price, setPrice] = useState({
    x: new Date().setSeconds(0, 0),
    y: [0, 0, 0, 0]
  })


  //차트관련 
  const [closePrice, setClosePrice] = useState(0)
  const [closeAt, setCloseAt] = useState('')
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)
  const [isClose, setIsClose] = useState(false)
  const [series, setSeries] = useState();

  //유저상태
  const { money } = useSelector(state => state.Data)
  const [currnetBetting, setCurrnetBetting] = useState({ long: 0, sort: 0 })

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setToastMsg(t("toast.copy_success"));
        openToast();
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getGameInfo()
  }, [])

  const getGameInfo = () => {
    api.get('/v1/FX/chartGameInfo',
      {
        params: {
          ticker: ticker
        },
      })
      .then((res) => {
        setViewTypeTable(res.data.content)
      })
      .catch((err) => { })
      .finally(() => {
      })
  }

  useEffect(() => {
    if (getValue.formValue.value < 0) {
      getValue.setFormValue((prevValues) => (
        {
          ...prevValues,
          value: "0"
        }));
    } else if (getValue.formValue.value * getValue.formValue.game_rule04 > money) {
      getValue.setFormValue((prevValues) => (
        {
          ...prevValues,
          value: parseInt(money / getValue.formValue.game_rule04)
        }));
    }
  }, [getValue.formValue.value])

  useEffect(() => {
    if (!viewTypeTable) return
    changeGameRule()
  }, [viewTypeTable])

  useEffect(() => {
    getPrice()
    if (!viewTypeTable) return
    changeGameRule()

  }, [getValue.formValue.game_rule01])

  const changeGameRule = async () => {
    if (!viewTypeTable) return
    if (type != getValue.formValue.game_rule01) {
      await navigate(`/${gameName}/${ticker}/${getValue.formValue.game_rule01}`)
    }
    // 전환
    await getValue.setFormValue((prevValues) => (
      {
        ...prevValues,
        game_rule02: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.game_rule01).game_rule02,
        game_rule03: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.game_rule01).game_rule03,
        game_rule04: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.game_rule01).game_rule04,
        game_rule05: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.game_rule01).game_rule05,
        label: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.game_rule01).label,
        game_no: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.game_rule01).game_no,
        value: '0',
      }));

    setDisplayRemainingSecondsRef(0)
    getBettingList()
  }


  useEffect(() => {
    if (displayRemainingSecondsRef < parseFloat(getValue.formValue.game_rule02) && displayRemainingSecondsRef >= 0) {
      setIsClose(true)
    } else {
      setIsClose(false)
    }
  }, [displayRemainingSecondsRef])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isClose == false) {
        // 정보 가지고 배팅 결과가지고 오기
        getBettingList()
      }
    }, 1000); // 1초 후 delayedCount를 count 값으로 설정
    return () => clearTimeout(timer); // 이전 타이머 정리
  }, [isClose])

  useEffect(() => {
    getBettingList()
  }, [activeBattingTab])

  const getBettingList = async () => {
    if (!viewTypeTable) return
    await api.get(activeBattingTab == 0 ? '/v1/FX/bettingList' : '/v1/FX/chartGameResult',
      {
        params: {
          game_no: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.game_rule01).game_no,
          game_rule01: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.game_rule01).game_rule01,
          search_game_no: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.search_type).game_no,
          search_game_rule01: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.search_type).game_rule01,
        },
      })
      .then((res) => {
        const { bettingList, currentBetting, currnetMoney } = res.data.content

        dispatch(updateMoney(currnetMoney.money))

        setCurrnetBetting({
          long: parseInt(currentBetting[0].lot),
          short: parseInt(currentBetting[1].lot)
        })

        if (activeBattingTab == 0) {
          bettingListData.setData(bettingList);
        }
        else {
          bettingToltalListData.setData(bettingList);
        }
      })
      .catch((err) => {
        console.log('베팅리스트 가져오기 실패');
      })
      .finally(() => {
        dispatch(loadingStatus(false));
      })
  }

  const bettingListData = useGetData({
    firstInit: true,
    api: getBettingList,
  });

  const bettingToltalListData = useGetData({
    firstInit: true,
    api: getBettingList,
  });

  const [sse, setSse] = useState(null); // EventSource 상태 관리

  const getPrice = async () => {
    if (sse) {
      sse.close();
    }

    const sseUrl = `${process.env.REACT_APP_BASE_URL}/v1/SSE/${ticker}-price?type=${getValue.formValue.game_rule01}`;

    const newSse = new EventSourcePolyfill(sseUrl);

    try {
      newSse.addEventListener("update", (e) => {
        if (e?.data) {
          try {
            const { yyyymmdd, start_hhmm, round_id, end_hhmm, game_type, game_no, start_val, max_val, min_val, end_val, time, remaining_seconds } = JSON.parse(e.data)
            const yyyy = yyyymmdd.substring(0, 4)
            const mm = parseInt(yyyymmdd.substring(4, 6)) - 1
            const dd = yyyymmdd.substring(6, 8)
            const hour = start_hhmm.substring(0, 2)
            const min = parseInt(start_hhmm.substring(3, 5)) - (parseInt(start_hhmm.substring(3, 5)) % getValue.formValue.game_rule01)
            const date = new Date(yyyy, mm, dd, hour, min)
            setPrice({
              x: new Date(date.setMinutes(date.getMinutes() - date.getMinutes() % getValue.formValue.game_rule01)).setSeconds(0, 0),
              y: [
                start_val, max_val, min_val, end_val
              ]
            })
            getValue.setFormValue((prevValues) => (
              {
                ...prevValues,
                round_id: round_id
              }));

          } catch (error) {
            console.error("Error parsing update event data:", error);
          }
        }
      });

      newSse.addEventListener("update2", (e) => {
        if (e?.data) {
          try {
            const { gameCount } = JSON.parse(e.data)

            setDisplayRemainingSecondsRef(gameCount)

          } catch (error) {
            console.error("Error parsing update event data:", error);
          }
        }
      });

      newSse.onerror = (error) => {
        const { status } = error
        if (status !== 401) {
          newSse.close();
          setTimeout(async () => {
            console.log("Retrying SSE connection...");
            await getPreviousChartData()
            getPrice(); // 재시도
          }, 5000);
        }
      };

      setSse(newSse);

    } catch (error) {
      newSse.close();
      setTimeout(async () => {
        console.log("Retrying SSE connection...");
        await getPreviousChartData()
        getPrice(); // 재시도
      }, 5000);
    }
  }

  useEffect(() => {
    if (getValue.formValue.game_no) {
      getPreviousChartData()

      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
    }
  }, [getValue.formValue.game_no]);

  const getPreviousChartData = () => {
    if (!viewTypeTable) return
    dispatch(loadingStatus(true));
    const apiUrl = "/v1/FX/previousChart";
    const end_time = new Date(Math.floor(Date.now()))
    const start_time = new Date(new Date(end_time - 45 * getValue.formValue.game_rule01 * 60 * 1000).setMinutes(
      new Date(end_time - 45 * getValue.formValue.game_rule01 * 60 * 1000).getMinutes() - new Date().getMinutes() % getValue.formValue.game_rule01))
    api
      .get(apiUrl, {
        params: {
          game_no: getValue.formValue.game_no,
          game_rule01: getValue.formValue.game_rule01,
          time: ((end_time.getHours() * 60) + end_time.getMinutes()) - ((start_time.getHours() * 60) + start_time.getMinutes()),
          ticker
        }
      })
      .then((res) => {
        setSeries([{ data: res.data.content.result }])
        setClosePrice(res.data.content.price)
        setCloseAt(res.data.content.time)
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch(loadingStatus(false));
      });
  };

  useEffect(() => {
    if (!price || !series || series[0]?.data.length == 0) return
    setSeries(prevSeries => {
      const lastDataPoint = prevSeries[0].data[prevSeries[0].data.length - 1];
      let updatedSeries = [...prevSeries]
      if (lastDataPoint.y[0] == 'NaN') return
      if (new Date(price.x).getMinutes() == new Date(lastDataPoint.x).getMinutes()) {
        updatedSeries[0].data.pop()
        updatedSeries[0].data.push(price)
      } else {
        updatedSeries[0].data.push(price)
      }
      if (prevSeries[0].data.length == 46) {
        updatedSeries[0].data.shift()
      }
      return updatedSeries; // 상태 갱신
    });

  }, [price.y[3]]);

  useEffect(() => {
    if (series) {
      setMax(Math.max(...series[0].data.map((item) => item.y[1])))
      setMin(Math.min(...series[0].data.map((item) => item.y[2])))
    }
  }, [series])

  const bettingPosition = (e) => {
    if (!viewTypeTable) return
    if (isClose) return;
    if (getValue.formValue.value == 0) return
    api.post(`v1/FX/chartBetting`, {
      lot: getValue.formValue.value,
      game_no: viewTypeTable.find((data) => data.game_rule01 == getValue.formValue.game_rule01).game_no,
      betting_pick: e,
      round_id: getValue.formValue.round_id,
      ticker,
    })
      .then((res) => {
        const { betting_pick, lot, succuss } = res.data.content
        if (succuss) {
          // 베팅내용 반영
          if (betting_pick == 'l') {
            setCurrnetBetting({ ...currnetBetting, long: currnetBetting.long + lot })
          } else {
            setCurrnetBetting({ ...currnetBetting, short: currnetBetting.short + lot })
          }
          // 머니 없앰.
          dispatch(updateMoney(money + (lot * -1 * getValue.formValue.game_rule04)))

          getBettingList()

          getValue.setFormValue((prevValues) => (
            { ...prevValues, value: '0' }));
        }
      })
      .catch((err) => {
        if (err.response.status == 400) {
          if (err.response.data.content.code == 'M001') {
            openAlert({
              title: t("common.notice"),
              message: t("chart.onebetplz"),
            });
          } else if (err.response.data.content.code == 'M002') {
            openAlert({
              title: t("common.notice"),
              message: t("chart.timeout"),
            });
          } else if (err.response.data.content.code == 'M003') {
            openAlert({
              title: t("common.notice"),
              message: t("chart.check_moneny"),
            });
          } else if (err.response.data.content.code == 'M007') {
            openAlert({
              title: t("common.notice"),
              message: t("error.blocked_member"),
            });
          }
        } else {
          showAlert()
        }
      })
  }

  const currency = () => {
    switch (ticker) {
      case "USDKRW":
        return "KRW"
      default:
        return "USD"
    }
  }
  const showAlert = () => {
    openAlert({
      title: t("common.notice"),
      message: t("error.unknown_betting"),
    });
  }

  const pressLotBtn = (e) => {
    getValue.setFormValue((prevValues) => ({
      ...prevValues,
      value: parseInt(getValue.formValue.value) + parseInt(e.target.value)
    }));
  }

  if (!series || series[0]?.data.length == 0) {
    return null
  }

  else if (error) {
    return (
      <Row $width='400px'>에러가 발생하였습니다!</Row>
    )
  }

  return (
    <Column $gap="6px">
      <Row>
        <FlexBox
          $width="100%"
          $align="center"
          $over="hidden"
          $pad={isMobile && "0px 0px"}
          $jus={!isMobile && "space-evenly"}
          $direct={isMobile ? "column" : "row"}
          $gap={isMobile && "5px"}>
          <Column $width={'100%'} $gap='5px'>
            <Row $align="baseline" $gap='5px'>
              <Column>
                {!isMobile && (<SelectForm
                  options={viewTypeTable}
                  onChangeEx={async (e) => {
                    await setSeries()
                  }}
                  placeholder={getValue.formValue.label}
                  name={"game_rule01"}
                  selectType="search"
                  size={'18px'}
                  {...getValue}
                  minWidth="190px"
                />)}
              </Column>
              {!isMobile && (
                <Column $width='100%' $align='right'>
                  <Row $align="baseline" $gap={isMobile ? '6px' : "8px"}>
                    <CurrentPriceBox $price={price.y[3]} $isMobile={isMobile}>
                      {parseFloat(price.y[3]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </CurrentPriceBox>
                    <TextBox $class={["caption", "gray300"]}>{currency()}</TextBox>
                    {ticker === "BTCUSD" && (
                      <>
                        <CurrentPercentBox $price={price.y[3] - closePrice}>
                          {parseFloat(price.y[3] - closePrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </CurrentPercentBox>
                        <CurrentPercentBox $price={price.y[3] - closePrice}>
                          {(((price.y[3] / closePrice) - 1) * 100).toFixed(2)} %
                        </CurrentPercentBox>
                      </>
                    )}
                  </Row>
                  {
                    ticker === "BTCUSD" && (
                      <Row >
                        <TextBox $class={["caption", "gray300"]} >
                          {`${new Date(closeAt).toLocaleString()} UTC9+ ${t('chart.closePrice')}`}
                        </TextBox>
                      </Row>
                    )
                  }
                </Column>
              )}
            </Row>
            <ReactApexChart
              type='candlestick'
              series={series}
              width={'100%'}
              height={isMobile ? 300 : 600}
              options={{
                theme: {
                  mode: "dark",
                },
                colors: [green],
                stroke: {
                  curve: 'smooth'
                },
                chart: {
                  id: 'realtime',
                  redrawOnParentResize: false,
                  animations: {
                    enabled: false,
                    easing: 'linear',
                    dynamicAnimation: {
                      enabled: false,
                      speed: 500
                    }
                    , animateGradually: {
                      enabled: false,
                      delay: 500 // 각 시리즈가 애니메이션하는 데 걸리는 시간
                    }
                  },
                  toolbar: {
                    show: true,
                    tools: {
                      download: false,
                      zoom: false,
                      zoomin: false,
                      zoomout: false,
                      pan: false,
                      reset: true, // 홈버튼 추가
                    },
                    autoSelected: 'zoom', // 기본 툴을 줌으로 설정
                  },
                  background: "dark",
                  zoom: {
                    enabled: false, // 휠로 확대/축소
                    type: 'x', // X, Y축 모두 확대/축소
                  },
                  events: {

                  },
                  stacked: true,
                },
                annotations: {
                  yaxis: [
                    {
                      y: price.y[3], // 현재 가격 (예: 110)
                      strokeDashArray: 8,
                      borderColor: red, // 가이드 라인의 색상
                      label: {
                        borderColor: red, // 라벨 테두리 색상
                        borderWidth: 2, // 테두리 두께
                        style: {
                          color: "#fff", // 라벨 텍스트 색상
                          background: red, // 라벨 배경색
                          fontSize: "12px", // 텍스트 크기
                        },
                        position: "left", // 라벨 위치 (left, right, top)
                        offsetX: -18,
                        offsetY: 8,
                        text: `${parseFloat(price.y[3]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, // 라벨 텍스트
                      },
                    },
                  ],
                },
                grid: {
                  show: true, // 그리드 표시 여부 (기본값은 true)
                  borderColor: '#777777', // 그리드의 색상
                  strokeDashArray: 0, // 그리드의 선 스타일 (0은 실선, 1은 점선)
                  position: 'back', // 그리드의 위치 ('front' or 'back')
                  xaxis: {
                    lines: {
                      show: false, // x축 그리드 선 표시 여부
                    }
                  },
                  yaxis: {
                    lines: {
                      show: true, // y축 그리드 선 표시 여부
                    }
                  }
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  enabled: false,
                  followCursor: true, // 커서를 따라 툴팁 위치 변경
                  fixed: {
                    enabled: false // 툴팁 고정 비활성화
                  },
                  custom: function ({ seriess, seriesIndex, dataPointIndex, w }) {
                    const ohlc = series[seriesIndex].data[dataPointIndex].y//w.globals.initialSeries[seriesIndex].data[dataPointIndex].y;
                    return `
                <div style="
                padding: 10px; 
                border: 1px solid '#ccc';  ">
                  <p>지수 정보</p>
                  ${t('chart.openPrice')}: ${parseFloat(ohlc[0]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<br>
                  ${t('chart.highPrice')}: ${parseFloat(ohlc[1]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<br>
                  ${t('chart.lowPrice')}: ${parseFloat(ohlc[2]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<br>
                  ${t('chart.closePrice')}: ${parseFloat(ohlc[3]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
              `;
                  }
                },
                xaxis: {
                  type: 'datetime',
                  tickAmount: 8,
                  labels: {
                    datetimeFormatter: {
                      minute: 'HH:mm',
                    },
                    datetimeUTC: false,
                  },
                  max: new Date().getTime() + 100000,  // 현재 시간까지
                  range: (1800000 + 900000) * getValue.formValue.game_rule01 + 100000,  // 45분의 범위를 유지
                  tooltip: {
                    enabled: false,
                    formatter: (value) => {
                      const date = new Date(value);
                      return date.toTimeString().slice(0, 5); // "HH:mm" 형식
                    },
                  },
                },
                yaxis: {
                  tooltip: {
                    enabled: false
                  },
                  labels: {
                    formatter: function (value) {
                      return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    }
                  },
                  forceNiceScale: true,
                  max: max * 1.005,
                  min: min * 0.995,
                },
              }}
            />
          </Column >
          <Column $minWidth={isMobile ? "300px" : "400px"} $gap='8px'>
            <Row $width='100%' $jus='space-between' $gap='5px' >
              <InputCon $pad={isMobile ? "6px 8px" : "12px 16px"}
                $radius="12px"
                $width="50%"
                //  룰2 < 남은 초
                $backColor={displayRemainingSecondsRef < parseFloat(getValue.formValue.game_rule02) && displayRemainingSecondsRef >= 0 ? red : null}>
                <TextBox $width="100%" $class={["title"]}>{t('chart.remainingTime')}</TextBox>
                {!displayRemainingSecondsRef < parseFloat(getValue.formValue.game_rule02) && displayRemainingSecondsRef >= 0 ?
                  <TextBox $width="100%">
                    {`${parseInt(displayRemainingSecondsRef)} ${t('chart.second')}`}
                  </TextBox> :
                  <TextBox >
                    {`${t('chart.tradeExpiration')} (${parseInt(displayRemainingSecondsRef)})`}
                  </TextBox>
                }
              </InputCon>
              <InputCon $pad={isMobile ? "6px 8px" : "12px 16px"} $radius="12px" $width="50%">
                <TextBox $width="100%" $class={["title"]}>{t('chart.nextTradingTime')}</TextBox>
                <TextBox $width="100%">{`${new Date(new Date().setMinutes(new Date().getMinutes() + parseInt(type))).getHours()}${t('chart.hour')} ${new Date(new Date().setMinutes(new Date().getMinutes() + parseInt(type))).getMinutes()
                  }${t('chart.minute')} 00${t('chart.second')}`}</TextBox>
              </InputCon>
            </Row>

            {!isMobile && (
              <TextBox $width="100%" $class={["title"]}>{t('chart.item')}</TextBox>
            )}

            <Column>
              <TextBox>{`${viewTypeTable.find((item) => item.game_rule01 == type).label}`}</TextBox>
              <Row $gap='6px' $align="baseline">
                <TextBox $color={parseFloat(price.y[3]) > parseFloat(closePrice) ? green : red}>
                  {`${parseFloat(price.y[3]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </TextBox>
                <TextBox $class={["caption", "gray300"]}>
                  {currency()}
                </TextBox>
                {
                  ticker === "BTCUSD" && (
                    <>
                      <TextBox $color={parseFloat(price.y[3]) > parseFloat(closePrice) ? green : red}>
                        {`${parseFloat(price.y[3] - closePrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                      </TextBox>
                      <TextBox $color={parseFloat(price.y[3]) > parseFloat(closePrice) ? green : red}>
                        {`${(((price.y[3] / closePrice) - 1) * 100).toFixed(2)} %`}
                      </TextBox>
                    </>
                  )
                }
              </Row>
            </Column>
            {!isMobile && (
              <TextBox $width="100%" $class={["title"]}>{t('chart.quantity')}</TextBox>
            )}
            <InputCon $pad="12px 16px" $radius="12px" $width="100%" >
              <Row $align="center">
                <InputForm
                  name="value"
                  type="number"
                  {...getValue}
                  backColor="var(--c-gray-700)"
                  disabled={isClose}
                />
                <Button
                  onClick={() => {
                    getValue.setFormValue((prevValues) => (
                      { ...prevValues, value: parseInt(money / getValue.formValue.game_rule04) }));
                  }}
                  disabled={isClose}
                >{t('chart.max')}</Button>
                <Button
                  $fontSize onClick={() => {
                    getValue.setFormValue((prevValues) => (
                      { ...prevValues, value: '0' }));
                  }}
                  disabled={isClose}
                >{t('chart.clear')}</Button>
              </Row>
            </InputCon>
            <Row $width="100%" $gap="5px" $jus='space-between'>
              <TextBox $class={["caption", "gray300"]}>
                {`${t('chart.orderAmount')} ${(getValue.formValue.value * getValue.formValue.game_rule04).toLocaleString()} ${t('chart.currency_unit')}`}
              </TextBox>
              <TextBox $class={["caption", "gray300"]}>
                {`${t('chart.holdingAmount')} ${money.toLocaleString()} ${t('chart.currency_unit')}`}
              </TextBox>
            </Row>
            {isMobile ? null :
              <Row $width="100%" $gap="5px">
                <Button
                  disabled={isClose}
                  $width='20%' value={2}
                  onClick={pressLotBtn}>
                  {`2 ${t('chart.ea')}`}
                </Button>
                <Button
                  disabled={isClose}
                  $width='20%' value={10}
                  onClick={pressLotBtn}>
                  {`10 ${t('chart.ea')}`}
                </Button>
                <Button
                  disabled={isClose}
                  width='20%' value={20}
                  onClick={pressLotBtn}>
                  {`20 ${t('chart.ea')}`}
                </Button>
                <Button
                  disabled={isClose}
                  $width='20%' value={50}
                  onClick={pressLotBtn}>
                  {`50 ${t('chart.ea')}`}
                </Button>
                <Button
                  disabled={isClose}
                  $width='20%' value={100}
                  onClick={pressLotBtn}>
                  {`100 ${t('chart.ea')}`}
                </Button>
              </Row>
            }

            <Row $width='100%' $jus='space-between'>
              <TextBox $class={["caption", "gray300"]}>
                {`${t('chart.minimumOrder')} 1 ${t('chart.ea')} = ${parseInt(getValue.formValue.game_rule04).toLocaleString()} ${t('chart.currency_unit')}`}
              </TextBox>
              <TextBox $class={["caption", "gray300"]}>
                {`${t('chart.realizationInterval')} ± ${getValue.formValue.game_rule03}`}
              </TextBox>
            </Row>

            {!isMobile && (
              <TextBox $width="100%" $class={["title"]}>{t('chart.buyPosition')}</TextBox>
            )}

            <TabContainer>
              <TabHeader>
                <UpButton
                  $isClose={isClose}
                  value={'l'}
                  onClick={() => { bettingPosition('l') }}>
                  {t('chart.longOrder', { rate: getValue.formValue.game_rule05 })}</UpButton>
                <DownButton
                  $isClose={isClose}
                  value={'s'}
                  onClick={() => { bettingPosition('s') }}
                >{t('chart.shortOrder', { rate: getValue.formValue.game_rule05 })}</DownButton>
              </TabHeader>
            </TabContainer>

            {!isMobile && (
              <TextBox $width="100%" $class={["title"]}>{t('chart.orderHistory')}</TextBox>
            )}

            <Row>
              <TextBox $width="100%" $class={["caption"]} $color={green}>
                {`${t('chart.long')} ▲ +${getValue.formValue.game_rule03}`}
              </TextBox>
              <TextBox $width="100%" $class={["caption"]}>
                {`${currnetBetting.long} ${t('chart.ea')}(${(currnetBetting.long * getValue.formValue.game_rule04).toLocaleString()} ${t('chart.currency_unit')})`}
              </TextBox>
            </Row>
            <Row>
              <TextBox $width="100%" $class={["caption"]} $color={red}>
                {`${t('chart.short')} ▼ -${getValue.formValue.game_rule03}`}
              </TextBox>
              <TextBox $width="100%" $class={["caption"]}>
                {`${currnetBetting.short} ${t('chart.ea')}(${(currnetBetting.short * getValue.formValue.game_rule04).toLocaleString()} ${t('chart.currency_unit')})`}
              </TextBox>
            </Row>
          </Column>
        </FlexBox>


      </Row>
      {/* 내역들.. */}
      <Row $pad="5px" $backColor="var(--c-gray-700)" $radius="12px" $width="300px" >
        {tabs.map((tab, index) => (
          <TabButton key={index} onClick={() => setActiveBattingTab(index)} >
            {tab.name}
          </TabButton>
        ))}
        <MovableBox $activeIndex={activeBattingTab} $isMobile={isMobile} $isTablet={isMiddleM} />
      </Row>
      <Row $gap={isMobile ? '6px' : '12px'} $mar='0px'>
        {
          viewTypeTable.map(({ label, key, game_rule01 }) => {
            return (
              <Row key={key}>
                <CheckRadio
                  name="search_type"
                  id={key}
                  value={game_rule01}
                  {...getValue}
                  defaultChecked={game_rule01 == getValue.formValue.search_type ? true : false}
                  onChange={(e) => {
                    getValue.formValue.search_type = e.target.value
                    getBettingList()
                  }}
                />
                <TextBox as="label" htmlFor={key} $class={["white"]} >
                  {isMobile ? `${game_rule01} ${t('chart.minuteRate')}` : ` ${label}`}
                </TextBox>
              </Row>
            )
          })
        }
      </Row>
      <Row $pad="" $backColor="var(--c-gray-700)" $radius={"12px"} $width='100%'>
        {
          activeBattingTab == 0 ?
            <ScrollBox $pad={isMobile ? "8px" : "20px"} $radius="12px" $backColor="var(--c-gray-700)">
              <Table className="table-box" $tLayout="fixed" $width="100%" >
                <thead>
                  <tr className="table-title">
                    <TableHeader $width={isScroll && "46px"} $minWidth={isScroll && "46px"}>{t("No")}</TableHeader>
                    <TableHeader $width={isScroll && "116px"} $minWidth={isScroll && "116px"}>{t("chart.t1_2")}</TableHeader>
                    <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>{t("chart.t1_3")}</TableHeader>
                    <TableHeader $width={isScroll && "56px"} $minWidth={isScroll && "56px"}>{t("chart.t1_4")}</TableHeader>
                    <TableHeader $width={isScroll && "86px"} $minWidth={isScroll && "86px"}>{t("chart.t1_5")}</TableHeader>
                    <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>{t("chart.t1_6")}</TableHeader>
                    <TableHeader $width={isScroll && "116px"} $minWidth={isScroll && "116px"}>{t("chart.t1_7")}</TableHeader>
                    <TableHeader $width={isScroll && "96px"} $minWidth={isScroll && "96px"}>{t("chart.t1_8")}</TableHeader>
                    <TableHeader $width={isScroll && "96px"} $minWidth={isScroll && "96px"}>{t("chart.t1_9")}</TableHeader>
                    <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>{t("chart.t1_10")}</TableHeader>
                    <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>{t("chart.t1_11")}</TableHeader>
                    <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>{t("chart.t1_12")}</TableHeader>
                    <TableHeader $width={isScroll && "136px"} $minWidth={isScroll && "136px"}>{t("chart.t1_13")}</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {bettingListData.data ?
                    bettingListData.data.map(({ after_money, before_money, betting_pick, charge, is_win,
                      no, order_at, order_lot, order_no, result_type, result_at, reward, game_rule_03 }, index) => {
                      return (
                        <TableRow className="table-content" key={index}>
                          <TableDeps $white={"wrap"} >{no}</TableDeps>
                          <TableDeps $white={"wrap"} >{order_at}</TableDeps>
                          <TableDeps $white={"nowrap"} $overflow={"hidden"} $tOverflow={"ellipsis"} onClick={async () => {
                            copyText(order_no)
                          }} title={order_no}>
                            <DuplicationIcon />
                            {order_no}
                          </TableDeps>
                          <TableDeps $white={"wrap"} $color={betting_pick == 'l' ? green : betting_pick == 's' ? red : null}>{betting_pick == 'l' ? t('chart.long') : t('chart.short')}</TableDeps>
                          <TableDeps $white={"wrap"} >{`${order_lot.toLocaleString()} ${t('chart.ea')}`}</TableDeps>
                          <TableDeps $white={"wrap"} >{`${(order_lot *
                            (viewTypeTable?.find((data) => data.game_rule01 == getValue.formValue.search_type)?.game_rule04)).toLocaleString()
                            } ${t('chart.currency_unit')}`}
                          </TableDeps>
                          <TableDeps $white={"wrap"} >{result_at || t('chart.currunt')}</TableDeps>
                          <TableDeps $white={"wrap"} $color={result_type == null ? null : result_type == 'U' || result_type == 'l' ? green : result_type == 'D' || result_type == 's' ? red : null}>
                            {result_type == null ? t('chart.currunt') : `${result_type == 'U' || result_type == 'l' ? `${t('chart.long')} +` : `${t('chart.short')} -`}${game_rule_03}`}
                          </TableDeps>
                          <TableDeps $white={"wrap"} $color={is_win == 1 ? green : null}>
                            {is_win == 1 ? t('chart.realized') : is_win == 0 ? t('chart.unrealized') : t('chart.currunt')}
                          </TableDeps>
                          <TableDeps $white={"wrap"} >
                            {reward == null ? t('chart.currunt') : `${reward.toLocaleString()} ${t('chart.currency_unit')}`}
                          </TableDeps>
                          <TableDeps $white={"wrap"} >{`${charge.toLocaleString()} ${t('chart.currency_unit')}`}</TableDeps>
                          <TableDeps $white={"wrap"} >{`${before_money.toLocaleString()} ${t('chart.currency_unit')}`}</TableDeps>
                          <TableDeps $white={"wrap"} >
                            {after_money == null ? t('chart.currunt') : `${after_money.toLocaleString()} ${t('chart.currency_unit')}`}
                          </TableDeps>
                        </TableRow>
                      );
                    }) : null}
                </tbody>
              </Table >
            </ScrollBox>

            :
            <ScrollBox $pad={isMobile ? "8px" : "20px"} $radius="12px" $backColor="var(--c-gray-700)">
              <Table className="table-box" $tLayout="fixed" $width="100%" >
                <thead>
                  <tr className="table-title">
                    <TableHeader $width={isScroll && "86px"} $minWidth={isScroll && "86px"}>{t("chart.t2_1")}</TableHeader>
                    <TableHeader $width={isScroll && "86px"} $minWidth={isScroll && "86px"}>{t("chart.t2_2")}</TableHeader>
                    <TableHeader $width={isScroll && "86px"} $minWidth={isScroll && "86px"}>{t("chart.t2_3")}</TableHeader>
                    <TableHeader $width={isScroll && "116px"} $minWidth={isScroll && "116px"}>{t("chart.t2_4")}</TableHeader>
                    <TableHeader $width={isScroll && "86px"} $minWidth={isScroll && "86px"}>{t("chart.t2_5")}</TableHeader>
                    <TableHeader $width={isScroll && "116px"} $minWidth={isScroll && "136px"}>{t("chart.t2_6")}</TableHeader>
                    <TableHeader $width={isScroll && "66px"} $minWidth={isScroll && "66px"}>{t("chart.t2_7")}</TableHeader>
                    <TableHeader $width={isScroll && "66px"} $minWidth={isScroll && "66px"}>{t("chart.t2_8")}</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {bettingToltalListData.data ?
                    bettingToltalListData.data.map(({
                      end_hhmm, end_val, game_rule_03,
                      no, seqNo, result_type, result_val,
                      start_hhmm, start_val }, index) => {
                      return (
                        <TableRow className="table-content" key={index}>
                          <TableDeps $white={"wrap"} >{no}</TableDeps>
                          <TableDeps $white={"wrap"} >{seqNo}</TableDeps>
                          <TableDeps $white={"wrap"} >{start_hhmm}</TableDeps>
                          <TableDeps $white={"wrap"} >{parseFloat(start_val).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableDeps>
                          <TableDeps $white={"wrap"}>{end_hhmm}</TableDeps>
                          <TableDeps $white={"wrap"}>{parseFloat(end_val).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableDeps>
                          <TableDeps $white={"wrap"}>{game_rule_03}</TableDeps>
                          <TableDeps $white={"wrap"} $color={result_type == 'U' || result_type == 'l' ? green : result_type == 'D' || result_type == 's' ? red : null}>{result_type == 'U' || result_type == 'l' ? t('chart.long') : result_type == 'D' || result_type == 's' ? t('chart.short') : t('chart.currunt')}</TableDeps>
                        </TableRow>
                      );
                    }) : null}
                </tbody>
              </Table >
            </ScrollBox>

        }
      </Row>
      {toastOpen && <Toast Icon={<CheckIcon />} toastText={toastMsg} />}
    </Column>
  )
};

const TabButton = styled.button`
  flex: 1;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  z-index: 1;
  &:hover {
    color: #7E51FF;
  }
`;

const MovableBox = styled.div`
  position: absolute;
  left: ${({ $activeIndex }) => `calc(${$activeIndex} * 280px /2)`}; /* 탭의 개수에 맞춰 움직이도록 계산 */
  margin-left: ${({ $isMobile, $isTablet }) => $isTablet ? '35px' : $isMobile ? '25px' : '35px'};
  width: calc(290px / 2);
  border-radius: 12px;
  height: 40px;
  background-color: var(--c-gray-500); /* 투명도 적용 */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: left 0.3s ease-in-out; /* 애니메이션 효과 */
`;

const CurrentPriceBox = styled.div`
  width : ${({ $isMobile }) => $isMobile ? '100px' : '100px'};
  text-align : right;
  font-size: ${({ $isMobile }) => $isMobile ? '14px' : '20px'} ;
  color: ${({ $price }) => $price < 0 ? 'red' : green};
`;

const CurrentPercentBox = styled.div`
  text-align : left;
  color: ${({ $price }) => $price < 0 ? 'red' : green};
`;


const Button = styled.button`
  margin-left: 5px;
  padding: 1px 10px;
  background-color: var(--c-gray-500);
  border: none;
  border-radius: 4px;
  font-size : 12px;
  width: ${($width) => $width || 'auto'};
  &:hover {
    background-color: var(--c-gray-600);
  }
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  border: 1px solid 'var(--c-gray-700)';
  border-radius: 12px;
  overflow: hidden;
`;

const TabHeader = styled.div`
  display: flex;
  height: 100%;
`;

const UpButton = styled.button`
  flex: 1;
  padding: 10px;
  background-color: ${({ $isClose }) => $isClose ? disbleGreen : green};
  color: white;
  border: none;
  outline: none;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
`;

const DownButton = styled.button`
  flex: 1;
  padding: 10px;
  background-color: ${({ $isClose }) => $isClose ? disbleRed : red};
  color: white;
  border: none;
  outline: none;
  position: relative;
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
`;

const tabs = [{ name: t('chart.recentTrades') },
{ name: t('chart.resultHistory') }];

export default Nasdaq;